import {
  FormulaBarTypes,
  ListColumnExprPrefix,
} from 'components/ui/codemirror-v2/formula-bar/constants';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { transformMathHintOptions } from 'data/formula-editor/utils/autocomplete-options';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { type List, useLists } from 'data/modelling/lists';
import { formatName } from 'utils/data-formatter';

export const useAutoCompletionOptions = ({
  ignoreFunctions,
}: {
  ignoreFunctions: boolean;
}): FormulaAutocompleteOption[] => {
  const { data: allLists = [] } = useLists();

  const listColumns: FormulaAutocompleteOption[] = [];

  for (const list of allLists) {
    const isDimGroup = !!(list as DimensionGroup)?.uniqueKeyColumns;

    if (!isDimGroup) {
      const formulaColumns = new Set(
        (list as List).config.columnFormulae?.map((colFormulae) => colFormulae.colName),
      );

      for (const listColumn of (list as List).config.columnOrder) {
        // skip formulae columns
        if (formulaColumns.has(listColumn)) {
          continue;
        }

        const colName = `${ListColumnExprPrefix}${list.name}.${listColumn}`;

        listColumns.push({
          id: colName,
          displayText: formatName(listColumn),
          secondaryDisplayText: list.displayName,
          formulaType: FormulaBarTypes.ListColumnExpression,
          type: FormulaBarTypes.ListColumnExpression,
          text: colName,
          replacementText: colName,
        });
      }
    } else {
      for (const listColumn of (list as DimensionGroup).columns) {
        const colName = `${ListColumnExprPrefix}${list.name}.${listColumn.colName}`;

        listColumns.push({
          id: colName,
          displayText: formatName(listColumn.colName),
          secondaryDisplayText: list.displayName,
          formulaType: FormulaBarTypes.ListColumnExpression,
          type: FormulaBarTypes.ListColumnExpression,
          text: colName,
          replacementText: colName,
        });
      }
    }
  }

  const functions = transformMathHintOptions([
    {
      id: 'CONCAT',
      displayText: 'Concat ()',
      text: 'CONCAT',
    },
    {
      id: 'LOOKUP',
      displayText: 'Lookup ()',
      text: 'LOOKUP',
    },
    {
      id: 'DATEDIFF',
      displayText: 'Datediff ()',
      text: 'DATEDIFF',
    },
    {
      id: 'DATEADD',
      displayText: 'Dateadd ()',
      text: 'DATEADD',
    },
  ]);

  return ignoreFunctions ? listColumns : [...functions, ...listColumns];
};
