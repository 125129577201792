import { useListRowValues } from 'components/modules/modelling/lists/hooks/use-list-column-values';
import { type OptionBase } from 'components/ui/atomic-components';
import { useSelectedColumnValues } from 'data/modelling/dimension';
import { ListDataValidationCondition, type ListConfig } from 'data/modelling/lists';
import { useMemo } from 'react';

interface Props {
  config: ListConfig;
  colName: string;
  listId: number;
}

export const useGenerateOptions = ({
  colName,
  config,
  listId,
}: Props): { isLoading: boolean; options: OptionBase[] } => {
  const columnHasExistingColumnValidation =
    config.dataValidation?.[colName]?.condition === ListDataValidationCondition.ExistingColumn;

  const { data: columnValidationOptions = [], isLoading: isLoadingColumnValidationOptions } =
    useSelectedColumnValues(
      columnHasExistingColumnValidation ? config.dataValidation?.[colName]?.source : undefined,
    );

  const { data: listRowValues = {}, isLoading: isLoadingListValueOptions } = useListRowValues({
    listId,
  });

  return useMemo(() => {
    if (columnHasExistingColumnValidation) {
      const options = columnValidationOptions
        .filter((option) => !!option.value)
        .map((option) => ({
          value: option.value,
          label: option.value,
        }));

      return { isLoading: isLoadingColumnValidationOptions, options };
    }

    return {
      isLoading: isLoadingListValueOptions,
      options: (listRowValues[colName] ?? [])
        .filter((value) => !!value)
        .map((value) => ({
          value,
          label: value,
        })),
    };
  }, [
    colName,
    columnHasExistingColumnValidation,
    isLoadingColumnValidationOptions,
    isLoadingListValueOptions,
    columnValidationOptions,
    listRowValues,
  ]);
};
