import { ReactComponent as CodeIcon } from 'assets/v2/code.svg';
import { ReactComponent as DataIcon } from 'assets/v2/database-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { ReactComponent as DownloadIcon } from 'assets/v2/download.svg';
import { ReactComponent as KeyIcon } from 'assets/v2/key.svg';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { type ItemType } from 'components/ui/atomic-components/menu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { useUser } from 'utils/hooks';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { exportListAsCsv } from './utils';

export const useListMenuOptions = ({
  usageWidgetMenuItem,
  onClickUniqueKeyOption,
  onClickUpdateFromDataOption,
  onClickDelete,
  onClickEditJson,
  onClickCleanupRecon,
  onClickTriggerDatasetBuild,
}: {
  usageWidgetMenuItem: ItemType;
  onClickUniqueKeyOption: () => void;
  onClickUpdateFromDataOption: () => void;
  onClickDelete: () => void;
  onClickEditJson: () => void;
  onClickCleanupRecon: () => void;
  onClickTriggerDatasetBuild: () => void;
}): ItemType[] => {
  const { isDtUser } = useUser();
  const { tempDerivedListRecon, tempSccl } = useFlags();

  const gridApi = useListStoreContext((s) => s.gridApi);
  const listName = useListStoreContext((s) => s.name);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const config = useListStoreContext((s) => s.config);
  const isDerivedList = useListStoreContext((s) => s.isDerivedList);

  const showOverflowMenu = hasListAccess(accessAction, false, config.backedByBQ).topOverflowMenu;
  const showUniqueKeyConfigure = hasListAccess(
    accessAction,
    false,
    config.backedByBQ,
  ).uniqueKeyConfigure;
  const isInVersion = isInVersionMode([VersionAccessModes.View, VersionAccessModes.SnapshotEdit]);

  return [
    {
      key: 'unique-key',
      icon: <IconShell icon={KeyIcon} />,
      label: <FormattedMessage id="lists.set_unique_key" />,
      onClick: onClickUniqueKeyOption,
      hidden: !tempSccl || !showUniqueKeyConfigure || isInVersion,
    },
    {
      key: 'update-from-data',
      icon: <IconShell icon={DataIcon} size="sm" />,
      label: <FormattedMessage id="lists.reconciliation.modal.title" />,
      onClick: onClickUpdateFromDataOption,
      hidden: !showOverflowMenu || !tempDerivedListRecon || isInVersion,
    },
    {
      key: 'download-csv',
      icon: <IconShell icon={DownloadIcon} />,
      label: <FormattedMessage id="lists.export_csv" />,
      onClick: () => exportListAsCsv({ gridApi, listName }),
    },
    ...(showOverflowMenu && !isInVersion ? [usageWidgetMenuItem] : []),

    {
      key: 'input-json',
      icon: <IconShell icon={CodeIcon} size="sm" />,
      label: <FormattedMessage id="lists.input_json.title" />,
      onClick: onClickEditJson,
      hidden: !isDtUser || isInVersion,
    },

    {
      key: 'trigger-dataset-build',
      icon: <IconShell icon={DataIcon} size="sm" />,
      label: <FormattedMessage id="lists.trigger_dataset_build.title" />,
      onClick: onClickTriggerDatasetBuild,
      hidden: isDerivedList,
    },

    {
      key: 'cleanup-recon',
      icon: <IconShell icon={DeleteIcon} size="sm" />,
      label: <FormattedMessage id="lists.cleanup_recon.title" />,
      onClick: onClickCleanupRecon,
      hidden: !isDtUser || isInVersion,
    },

    ...(showOverflowMenu && !isInVersion ? [{ key: 'divider', type: 'divider' } as ItemType] : []),

    {
      key: 'delete-list',
      danger: true,
      icon: <IconShell icon={DeleteIcon} />,
      label: <FormattedMessage id="lists.delete_list" />,
      hidden: !showOverflowMenu || isInVersion,
      onClick: onClickDelete,
    },
  ];
};
