import { generateSeriesColorMap } from 'components/ui/chart-components-v2/chart-types/common/color-logic';
import { type ID } from 'data';
import { ChartType, type Chart, type ChartAttributes } from 'data/boards/charts/types';
import { type FrameElement } from 'data/reports/types';
import { SCENARIO } from 'data/scenarios';
import { isEmpty } from 'lodash';

const updateSeriesColorMap = ({
  seriesColorMap,
  rows,
  versionNames,
  chartId,
  isScenarioPresent,
  hideAggregatesFor,
}: {
  seriesColorMap: Record<string, string>;
  rows: FrameElement[];
  versionNames: string[];
  chartId: ID;
  isScenarioPresent: boolean;
  hideAggregatesFor: string[];
}): Record<string, string> => {
  const newSeriesColorMap = generateSeriesColorMap({
    rows,
    chartVersions: versionNames,
    chartId,
    isScenarioPresent,
    hideAggregatesFor,
  });

  return { ...newSeriesColorMap, ...seriesColorMap };
};

export const preProcessAttributes = (
  chart?: Chart,
  enableConsistentChartColorsForAutomation?: boolean,
): ChartAttributes => {
  if (
    !chart ||
    isEmpty(chart.attributes?.metricNames) ||
    [
      ChartType.Table,
      ChartType.Scorecard,
      ChartType.Waterfall,
      ChartType.PlanTable,
      ChartType.List,
    ].includes(chart.type)
  ) {
    return chart?.attributes || {};
  }

  const rows = chart.frame?.rows || [];
  const {
    rows: rowsOrder = [],
    versionNames = [],
    seriesColorMap = {},
    parentChartId,
    hideAggregatesFor = [],
  } = chart?.attributes || {};

  const updatedSeriesColorMap = updateSeriesColorMap({
    seriesColorMap,
    rows,
    versionNames,
    chartId: enableConsistentChartColorsForAutomation ? 0 : parentChartId || chart.id,
    isScenarioPresent: rowsOrder.includes(SCENARIO),
    hideAggregatesFor,
  });

  return {
    ...chart.attributes,
    seriesColorMap: updatedSeriesColorMap,
  };
};
