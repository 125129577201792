import { ReactComponent as DatabaseIcon } from 'assets/v2/database.svg';
import { ReactComponent as ListIcon } from 'assets/v2/menu-2.svg';
import { IconShell } from 'components/ui/atomic-components';
import { isEmpty } from 'lodash';
import { type ReactElement, type ReactNode } from 'react';
import { useListStoreContext } from 'store/lists';
import { useUser } from 'utils/hooks';
import { DerivedListDescription } from './derived-list-description';
import { Description } from './description';
import { ErrorPopover } from './error-popover';
import { MutationSpinner } from './mutation-spinner';
import { ReconUpdatesPill } from './recon-updates-pill';
import { styles } from './styles';
import { InlineTitleInput } from './title';

const { Wrapper, Title, ActionButtonsSection, TitleSpinner, TitleIconWrapper, TitleDescWrapper } =
  styles;

interface Props {
  listName: string;
  rightItems: ReactNode;
  isDerivedList?: boolean;
  isReadOnly?: boolean;
  reconciliationEnabled?: boolean;
}

export const GridTopBar = ({
  listName,
  rightItems,
  isDerivedList,
  isReadOnly,
  reconciliationEnabled,
}: Props): ReactElement => {
  const { isDtUser } = useUser();

  const errors = useListStoreContext((state) => state.errors);
  const isReconList = !isEmpty(useListStoreContext((state) => state.reconciliationConfig));

  return (
    <Wrapper>
      <Title>
        <TitleDescWrapper>
          <TitleSpinner>
            <TitleIconWrapper>
              <IconShell color="textBody2" icon={isReconList ? DatabaseIcon : ListIcon} />
              <InlineTitleInput isReadOnly={isReadOnly} name={listName} />
            </TitleIconWrapper>

            <MutationSpinner />
            {!isEmpty(errors) && isDtUser && <ErrorPopover errors={errors} />}

            {reconciliationEnabled && <ReconUpdatesPill />}
          </TitleSpinner>

          {isDerivedList ? (
            <DerivedListDescription isReadOnly={isReadOnly} />
          ) : (
            <Description isReadOnly={isReadOnly} />
          )}
        </TitleDescWrapper>
      </Title>

      <ActionButtonsSection>{rightItems}</ActionButtonsSection>
    </Wrapper>
  );
};
