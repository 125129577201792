import { DotFlashingLoader } from 'components/ui/atomic-components/dot-loader';
import { AnimatePresence, motion } from 'framer-motion';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';

const LoadingMessage = styled(motion.div)`
  ${FontXs};

  color: ${({ theme }) => theme.colors.textBody2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  position: absolute;
  left: 16px;
  top: -12px;
  display: flex;
  align-items: center;
`;

interface Props {
  isLoading: boolean;
}

export const FooterLoader = ({ isLoading }: Props): ReactElement => {
  return (
    <AnimatePresence>
      {isLoading && (
        <LoadingMessage
          animate={{ y: 0 }}
          exit={{ y: 16, opacity: 0.5 }}
          initial={{ y: 16 }}
          transition={{
            duration: 0.3,
          }}
        >
          <FormattedMessage id="drive_ai.input.loading" />

          <DotFlashingLoader />
        </LoadingMessage>
      )}
    </AnimatePresence>
  );
};
