import { ReactComponent as XIcon } from 'assets/v2/x.svg';
import { type ReactNode, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconShell } from '../icon-shell';
import styles from './styles';

const { Wrapper, BannerBar, BannerTag, Title, CloseWrapper, SaveButton } = styles;

interface Props {
  title: ReactNode;
  icon?: ReactNode;
  onClose?: () => void;
  onSave?: () => void;
}

export const Banner = ({ title, icon, onClose, onSave }: Props): ReactElement => {
  return (
    <Wrapper>
      <BannerBar />

      <BannerTag>
        <Title>
          {icon}
          {title}
        </Title>

        {onSave && (
          <SaveButton size="extraSmall" onClick={onSave}>
            <FormattedMessage id="save" />
          </SaveButton>
        )}

        {onClose && (
          <CloseWrapper onClick={onClose}>
            <IconShell icon={XIcon} />
          </CloseWrapper>
        )}
      </BannerTag>
    </Wrapper>
  );
};
