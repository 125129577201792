import {
  MathFunctions,
  type MathFunction,
} from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { FormulaBarTypes } from 'components/ui/codemirror-v2/formula-bar/constants';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import {
  DatasetDimPrefix,
  DimGroupPrefix,
  TimeDimensionName,
  type DimensionProperty,
} from 'data/dimension';
import { type MonitoringMetric } from 'data/metric-builder';
import { type PlanMetric } from 'data/modelling/metric';
import { formatName } from 'utils/data-formatter';

export const transformMetricHintOptions = (
  metrics: PlanMetric[] | MonitoringMetric[],
  lhsMetricName?: string,
): FormulaAutocompleteOption[] => {
  return (
    metrics.map((metric) => {
      return {
        id: metric.name,
        displayText: metric.displayName,
        secondaryDisplayText: (metric as PlanMetric).moduleDisplayName,
        formulaType: FormulaBarTypes.MetricExpression,
        type: `${FormulaBarTypes.MetricExpression}-${metric.type}`,

        replacementText:
          metric.name === lhsMetricName
            ? `${metric.name}[${TimeDimensionName}=${TimeDimensionName}-1]` // default time filter to prev month for lhs metric
            : metric.name,
        text: metric.name,
      };
    }) ?? []
  );
};

const getMathFunctionReplacementText = (option: MathFunction, lhsMetricName?: string) => {
  if (['average', 'sum'].includes(option.id)) {
    const arg1 = `${lhsMetricName ?? '_placeholder_'}`;

    return `${option.text} ( ${arg1} , dims.t - 3 , dims.t - 1 )`;
  }

  return `${option.text} (  )`;
};

export const transformMathHintOptions = (
  mathFunctions: MathFunction[],
  lhsMetricName?: string,
): FormulaAutocompleteOption[] => {
  return mathFunctions.map((option) => {
    return {
      ...option,
      replacementText: getMathFunctionReplacementText(option, lhsMetricName),
      secondaryDisplayText: 'Function',
      formulaType: FormulaBarTypes.Math,
      type: FormulaBarTypes.Math,
    };
  });
};

export const transformTimePeriodHintOption = (): FormulaAutocompleteOption => {
  return {
    id: 'time.period',
    displayText: 'Period',
    secondaryDisplayText: 'Keyword',
    formulaType: FormulaBarTypes.Period,
    type: FormulaBarTypes.Period,
    replacementText: 'time.period',
    text: 'Period',
  };
};

export const transformThisMonthHintOption = (): FormulaAutocompleteOption => {
  return {
    id: 'dims.t',
    displayText: 'This month',
    secondaryDisplayText: 'Keyword',
    formulaType: FormulaBarTypes.Text,
    type: FormulaBarTypes.Text,
    replacementText: 'dims.t',
    text: 'This month',
  };
};

export const transformDimensionHintOptions = (
  finalDimensionProperties?: DimensionProperty[],
): FormulaAutocompleteOption[] => {
  const transformedOptions = [];

  if (finalDimensionProperties) {
    for (const dimProp of finalDimensionProperties) {
      if (
        !dimProp.fqName?.startsWith(DatasetDimPrefix) &&
        !dimProp.fqName?.startsWith(DimGroupPrefix)
      ) {
        const dimensionName = dimProp.fqName?.split('.')?.at(0);

        if (dimensionName) {
          const option: FormulaAutocompleteOption = {
            id: dimensionName,
            displayText: formatName(dimProp.name),
            secondaryDisplayText: dimProp.tableDisplayName,
            formulaType: FormulaBarTypes.DimensionExpression,
            type: FormulaBarTypes.DimensionExpression,
            replacementText: `dims.${dimensionName}.${dimProp.name}`,
            text: dimensionName,
          };

          transformedOptions.push(option);
        }
      }
    }
  }

  return transformedOptions;
};

export const transformDatasetHintOptions = (
  finalDimensionProperties?: DimensionProperty[],
  dimensionName?: string,
): FormulaAutocompleteOption[] => {
  const transformedOptions = [];

  if (dimensionName && finalDimensionProperties) {
    for (const dimProp of finalDimensionProperties) {
      if (dimProp.fqName?.startsWith(DatasetDimPrefix)) {
        const dimPropDataset = dimProp.fqName.split('.').at(1);
        const dimPropTable = dimProp.fqName.split('.').at(2);

        if (dimPropDataset && dimPropTable) {
          const option: FormulaAutocompleteOption = {
            id: dimPropTable,
            displayText: formatName(dimProp.name),
            secondaryDisplayText: dimProp.tableDisplayName,
            formulaType: FormulaBarTypes.DatasetExpression,
            type: FormulaBarTypes.DatasetExpression,
            replacementText: `dims.${dimProp.fqName}`,
            text: dimensionName,
          };

          transformedOptions.push(option);
        }
      }
    }
  }

  return transformedOptions;
};

export const transformDimGroupHintOptions = (
  finalDimensionProperties?: DimensionProperty[],
): FormulaAutocompleteOption[] => {
  const transformedOptions = [];

  if (finalDimensionProperties) {
    for (const dimProp of finalDimensionProperties) {
      if (dimProp.fqName?.startsWith(DimGroupPrefix)) {
        const dimGroupName = dimProp.fqName.split('.').at(1);
        const dimGroupCol = dimProp.fqName.split('.').at(2);

        if (dimGroupName && dimGroupCol) {
          const option: FormulaAutocompleteOption = {
            id: dimGroupName,
            displayText: formatName(dimGroupCol),
            secondaryDisplayText: dimProp.tableDisplayName,
            formulaType: FormulaBarTypes.DimensionGroupExpression,
            type: FormulaBarTypes.DimensionGroupExpression,
            replacementText: `dims.${dimProp.fqName}`,
            text: dimGroupCol,
          };

          transformedOptions.push(option);
        }
      }
    }
  }

  return transformedOptions;
};

export const transformLastActualsDateOption = (): FormulaAutocompleteOption => {
  const lastActualsDateOption = {
    id: 'time.moduleActualsEndDate',
    displayText: 'Last actuals date',
    formulaType: FormulaBarTypes.LastActualsDate,
    type: FormulaBarTypes.LastActualsDate,
    replacementText: 'time.moduleActualsEndDate',
  };

  return lastActualsDateOption;
};

export const generateAutocompleteOptions = (
  metricDimensionNames: string[],
  finalDimensionProperties: DimensionProperty[],
  metrics: PlanMetric[],
  lhsMetricName: string,
): FormulaAutocompleteOption[] => {
  const metricOptions = transformMetricHintOptions(metrics, lhsMetricName);
  const mathOptions = transformMathHintOptions(MathFunctions, lhsMetricName);
  const dimensionOptions = transformDimensionHintOptions(finalDimensionProperties);
  const datasetOptions = transformDatasetHintOptions(
    finalDimensionProperties,
    metricDimensionNames.at(-1),
  );
  const dimGroupOptions = transformDimGroupHintOptions(finalDimensionProperties);
  const timePeriodOption = transformTimePeriodHintOption();
  const lastActualsDateOption = transformLastActualsDateOption();
  const thisMonthOption = transformThisMonthHintOption();

  return [
    ...metricOptions,
    ...mathOptions,
    ...datasetOptions,
    ...dimGroupOptions,
    ...dimensionOptions,
    timePeriodOption,
    lastActualsDateOption,
    thisMonthOption,
  ];
};
