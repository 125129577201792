import styled from 'styled-components';

export const styles = {
  Wrapper: styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${({ theme: { spacing } }) => `${spacing[16]} ${spacing[12]}`};
    border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    margin-bottom: ${({ theme }) => theme.spacing[16]};
  `,

  Title: styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing[4]};
    flex: 1;
    overflow: hidden;
  `,

  TitleSpinner: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[8]};
  `,
  TitleIconWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
  `,

  TitleDescWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
    flex: 1;
    overflow: hidden;
  `,

  ActionButtonsSection: styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing[8]};
  `,
};
