import { css, keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: 200% 0;
  }
  
  100% {
    background-position: -200% 0;
  }
`;

export const ShimmerText = css`
  background: linear-gradient(90deg, #6b7280 25%, #f3f4f6 50%, #6b7280 75%);
  background: ${({ theme: { colors } }) =>
    `linear-gradient(90deg, ${colors.gray500} 25%, ${colors.gray100} 50%, ${colors.gray500} 75%)`};
  background-size: 200% 100%;
  background-clip: text;
  color: transparent;
  animation: ${shimmer} 1400ms linear infinite;
`;
