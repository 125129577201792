import { type BaseCellEditor } from 'ag-grid-community';
import { type CustomCellEditorProps } from 'ag-grid-react';
import { CreatableSelect } from 'components/ui/atomic-components/select/creatable';
import { ListDataValidationCondition } from 'data/modelling/lists';
import {
  type KeyboardEvent,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
} from 'react';
import { type SelectInstance } from 'react-select';
import { useListStoreContext } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { checkIfLastRow } from '../utils';
import { CreateLabel } from './create-label';
import { CustomMenuList } from './select-compnents/menu-list';
import { CustomMenuListForValidatedColumn } from './select-compnents/menu-list-for-validated-column';
import styles from './styles';
import { type TextOptionType } from './types';
import { useGenerateOptions } from './use-generate-options';
import { filterOption, generateInitialValue, getSelectedOptions } from './utils';

const { Wrapper, selectStyles } = styles;

export const TextCellEditor = forwardRef<BaseCellEditor, CustomCellEditorProps>(
  (
    {
      value,
      initialValue,
      column,
      api,
      rowIndex,
      eventKey,
      onKeyDown: defaultOnKeyDown,
      stopEditing,
      onValueChange,
    },
    ref,
  ) => {
    const colId = column.getId();
    const cellWidth = column.getActualWidth();

    const selectRef = useRef<SelectInstance<TextOptionType>>(null);
    const lastKeyPressed = useRef<string | undefined>();

    const listId = useListStoreContext((s) => s.id);
    const config = useListStoreContext((s) => s.config);

    const isSelectiveAccessColumn = !!config.selectiveAccessColumns?.includes(colId);

    const [inputValue, setInputValue] = useState<string | undefined>(
      generateInitialValue(eventKey) || value,
    );

    const [hasUserChangedInput, setHasUserChangedInput] = useState(
      !!generateInitialValue(eventKey),
    );

    const [selectedOption, setSelectedOption] = useState<TextOptionType | null>(
      getSelectedOptions(value),
    );

    const { options, isLoading: optionsIsLoading } = useGenerateOptions({
      colName: colId,
      config,
      listId,
    });

    const filteredOptions = useMemo(() => {
      // show only first 50 to improve select render perf
      const maxItemsToShow = 50;

      if (inputValue && hasUserChangedInput) {
        return options
          .filter((option) =>
            (option.value || '').toLowerCase().includes(inputValue?.toLowerCase()),
          )
          .slice(0, maxItemsToShow);
      }

      return options.slice(0, maxItemsToShow);
    }, [options, inputValue, hasUserChangedInput]);

    const columnHasExistingColumnValidation =
      config.dataValidation?.[colId]?.condition === ListDataValidationCondition.ExistingColumn;

    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Escape') {
        onValueChange(initialValue);
        stopEditing(true);
      }

      if (e.code === 'Tab') {
        if (!isSelectiveAccessColumn) {
          onValueChange(inputValue);
          setSelectedOption({ value: inputValue, label: inputValue } as TextOptionType);
        }

        lastKeyPressed.current = 'Tab';
        setTimeout(() => defaultOnKeyDown(e as unknown as globalThis.KeyboardEvent), 20);
      }

      if (e.code === 'Enter') {
        // if in last row, don't move focus down
        if (checkIfLastRow({ rowIndex: rowIndex + 1, api })) {
          onBlur();
        } else {
          lastKeyPressed.current = 'Enter';
          queueMacroTask(() => defaultOnKeyDown(e as unknown as globalThis.KeyboardEvent));
        }
      }
    };

    const onBlur = () => {
      if (!isSelectiveAccessColumn) {
        onValueChange(inputValue);
        setSelectedOption({ value: inputValue, label: inputValue } as TextOptionType);
      }

      if (lastKeyPressed.current === 'Enter') {
        queueMacroTask(() => stopEditing());
      } else if (lastKeyPressed.current !== 'Tab') {
        queueMacroTask(() => stopEditing(true));
      }
    };

    useImperativeHandle(ref, () => {
      return {
        isCancelAfterEnd: () => value === initialValue,
      };
    });

    return (
      <Wrapper $width={cellWidth}>
        <CreatableSelect<TextOptionType>
          ref={selectRef}
          autoFocus
          components={{
            MenuList: columnHasExistingColumnValidation
              ? CustomMenuListForValidatedColumn
              : CustomMenuList,
            ClearIndicator: () => <></>,
          }}
          createOptionPosition="first"
          filterOption={(option, inputValue) =>
            filterOption({ option, inputValue, isSelectiveAccessColumn })
          }
          formatCreateLabel={(inputValue) => <CreateLabel inputValue={inputValue} />}
          inputValue={inputValue}
          isClearable
          isLoading={optionsIsLoading}
          isSearchable
          isValidNewOption={columnHasExistingColumnValidation ? () => false : undefined}
          menuPortalTarget={document.body}
          noOptionsMessage={() => null}
          openMenuOnFocus
          options={filteredOptions}
          styles={selectStyles}
          value={selectedOption}
          onBlur={onBlur}
          onChange={(option) => {
            setSelectedOption(option);
            onValueChange(option?.value);

            queueMacroTask(() => stopEditing(true));
          }}
          onInputChange={(inputValue, actionMeta) => {
            if (actionMeta.action === 'menu-close') {
              return;
            }

            setHasUserChangedInput(true);
            setInputValue(inputValue);

            queueMacroTask(() => selectRef.current?.focusOption());
          }}
          onKeyDown={onKeyDown}
        />
      </Wrapper>
    );
  },
);

TextCellEditor.displayName = 'TextCellEditor';
