import { type Frame } from 'data/reports/types';
import { eliminateChainOfLoneBlanks } from './eliminate-chain-of-lone-blanks';

export const preProcessFrame = (frame: Frame, isChartInTabularView?: boolean): Frame => {
  if (isChartInTabularView) {
    return frame;
  }

  return { rows: eliminateChainOfLoneBlanks(frame.rows), columns: frame.columns };
};
