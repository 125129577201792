import axios from 'axios';
import { type MetricCategory } from 'components/modules/boards-v2/custom-board/pivot-slider/left-section/data-tab/list/types';
import { type ID, type Granularity } from 'data';
import { type ItemMoveRequest } from 'data/folder';
import { type BaseMetric } from 'data/metrics';
import { type RawData } from 'data/reports/types';
import { BASE_CASE_NAME } from 'data/scenarios';
import { composeGranularities } from 'data/utils';
import { isEmpty } from 'lodash';
import { type PlanTableFormula } from 'store/reports/types';
import { type DecodedValueMap } from 'use-query-params';
import { formatDimNames } from 'utils/data-formatter';
import { processLastFilterDimNameToSendInApi } from 'utils/dimensions';
import { type ModuleMetric } from '../metric';
import { type ModuleQueryParams } from './hooks';
import { type Module } from './types';

const ROOT_PATH = '/v1/models/m';

export const ModuleApi = {
  async findRecord(
    moduleName: string,
    query: DecodedValueMap<ModuleQueryParams>,
    options?: {
      metricsToFullLoad?: string[];
      pathsToLoad?: { metric: string; dimensions: Record<string, string> }[];
    },
  ): Promise<Module> {
    const granularity = composeGranularities(query?.rollup as Granularity[]).join(',');

    const { Scenario } = query;

    const data = await axios
      .post(`${ROOT_PATH}/${moduleName}`, {
        granularity,
        fetchMetrics: true,
        pivot:
          !isEmpty(query.f) || !isEmpty(query.Scenario)
            ? {
                dimensions: {
                  filters: query.f,
                  scenarios: [Scenario || BASE_CASE_NAME],
                  ...(!isEmpty(query.lastFilterDimName)
                    ? {
                        lastFilterDimName: processLastFilterDimNameToSendInApi(
                          `module-${moduleName}`,
                          query.lastFilterDimName,
                        ),
                      }
                    : {}),
                },
              }
            : null,

        metricsToFullLoad: options?.metricsToFullLoad,
        ...(options?.pathsToLoad
          ? {
              lazyLoadingConfig: {
                pathsToLoad: options?.pathsToLoad,
              },
            }
          : {}),
      })
      .then(({ data }) => data);

    formatDimNames(data?.granularDimensions);
    formatDimNames(data?.dimensions);
    formatDimNames(data?.pageDimensions);
    data?.metrics?.forEach((metric: ModuleMetric) => formatDimNames(metric?.dimensions));

    return data;
  },

  async lazyLoad(
    moduleName: string,
    query: DecodedValueMap<ModuleQueryParams>,
    solveId: string,
    pathsToLoad: { metric: string; dimensions: Record<string, string> }[],
  ): Promise<{ data: RawData[]; solveId: string }> {
    const granularity = composeGranularities(query?.rollup as Granularity[]).join(',');

    const { Scenario } = query;

    const data = await axios
      .post(`${ROOT_PATH}/${moduleName}/lazy-load`, {
        granularity,
        pivot:
          !isEmpty(query.f) || !isEmpty(query.Scenario)
            ? {
                dimensions: {
                  filters: query.f,
                  scenarios: [Scenario || BASE_CASE_NAME],
                },
              }
            : null,
        lazyLoadingConfig: {
          solveId,
          pathsToLoad,
        },
      })
      .then(({ data }) => data);

    return data;
  },

  createRecord(
    module: Pick<Module, 'displayName' | 'folderId' | 'attributes'> & {
      description?: string;
      dimensions: { name: string }[];
    },
  ): Promise<{ name: string }> {
    return axios.post(ROOT_PATH, module).then(({ data }) => data);
  },

  update(moduleName: string, module: Partial<Module>): Promise<void> {
    return axios.put(`${ROOT_PATH}/${moduleName}`, module);
  },

  move(itemMoveRequest: ItemMoveRequest): Promise<void> {
    return axios.patch(`${ROOT_PATH}/${itemMoveRequest?.entityName}`, {
      folderId: itemMoveRequest?.folderId,
    });
  },

  patch(
    moduleName: string,
    module: Omit<Partial<Module>, 'dimensions'> & { dimensions?: { name: string }[] },
  ): Promise<Pick<Module, 'attributes' | 'prevPeriods' | 'futurePeriods'>> {
    return axios.patch(`${ROOT_PATH}/${moduleName}`, module).then(({ data }) => data);
  },

  delete(moduleName?: string): Promise<void> {
    return axios.delete(`${ROOT_PATH}/${moduleName}`);
  },

  async createMetric(
    moduleName: string,
    metricName: string,
    dual?: string | null,
  ): Promise<ModuleMetric> {
    const { data } = await axios.post(`${ROOT_PATH}/${moduleName}/metrics`, {
      displayName: metricName,
      dual,
    });

    return data;
  },

  reorder: (
    modules: {
      name: string;
    }[],
  ): Promise<void> => {
    return axios.post(`${ROOT_PATH}/reorder`, modules);
  },

  async importActuals(moduleName: string, startDate: string, endDate: string): Promise<Module> {
    const rollup = composeGranularities().join(',');

    const { data } = await axios.post(`${ROOT_PATH}/${moduleName}/import-actuals`, {
      rollup,
      startDate,
      endDate,
    });

    return data;
  },

  async clearActuals(moduleName: string): Promise<Module> {
    const rollup = composeGranularities().join(',');

    const { data } = await axios.post(`${ROOT_PATH}/${moduleName}/clear-actuals`, {
      rollup,
    });

    return data;
  },
  downloadExcelWithFormula: (modelName: string): Promise<Uint8Array> =>
    axios
      .get(`v1/models/m/${modelName}/excel`, { responseType: 'arraybuffer' })
      .then(({ data }) => new Uint8Array(data))
      .catch((err) => {
        return new Uint8Array();
      }),

  async cloneVariable(moduleName: string, metricName: string): Promise<BaseMetric> {
    const { data } = await axios.post(`${ROOT_PATH}/${moduleName}/clone`, {
      name: metricName,
    });

    return data;
  },

  getModulesWithPlanMetrics: async (versionId?: ID): Promise<MetricCategory> =>
    await axios.get('v1/modules', { params: { versionId } }).then(({ data }) => data),

  addFormulaToPlanMetrics: async (
    formulae: PlanTableFormula,
    chartId: number,
  ): Promise<boolean> => {
    const { data } = await axios.post('v1/m/formulae', {
      metrics: formulae,
      chartId,
    });

    return data.success;
  },
};
