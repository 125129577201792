import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { ReactComponent as XIcon } from 'assets/v2/x.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontM } from 'styles/typography';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { spacing } }) => `${spacing[12]} ${spacing[16]}`};
`;

const Title = styled.div`
  ${FontM};

  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textBody2};
`;

const Actions = styled.div`
  gap: ${({ theme }) => theme.spacing[8]};
`;

interface Props {
  onClose: () => void;
  onReset: () => void;
}

export const TitleBar = ({ onClose, onReset }: Props): ReactElement => {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="drive_ai.title" />
      </Title>

      <Actions>
        <Button icon={<IconShell icon={PlusIcon} />} type="text" onClick={onReset} />
        <Button icon={<IconShell icon={XIcon} />} type="text" onClick={onClose} />
      </Actions>
    </Wrapper>
  );
};
