import { type UseMutationResult } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components';
import { Analytics } from 'config/analytics';
import { type ID, EntityStatus } from 'data';
import {
  ListUpdateActionType,
  type ListUpdate,
  ListUpdateType,
  type ListUpdateRequest,
} from 'data/modelling/lists';
import { isEmpty } from 'lodash';
import { useEffect, useState, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CardHighlight } from './card-highlight';
import { LeftSection } from './left-section';
import { RightSection } from './right-section';
import { getCardColor } from './utils/get-card-color';

const Wrapper = styled.div`
  display: flex;
  min-height: 86px;
`;

interface Props {
  listUpdate: ListUpdate;
  listUpdateActionMutation: UseMutationResult<
    ListUpdate,
    AxiosError,
    {
      isGroupUpdate: boolean;
      updateId: number;
      request: ListUpdateRequest;
    },
    unknown
  >;
  doOlderReconsExist: (listUpdate: ListUpdate) => boolean;
  setDisplayIdx: () => void;
}

export const UpdateActionCard = ({
  listUpdate,
  listUpdateActionMutation,
  doOlderReconsExist,
  setDisplayIdx,
}: Props): ReactElement => {
  const intl = useIntl();
  const [isRecommendation, setIsRecommendation] = useState(false);
  const [pickedRecommendationRowId, setPickedRecommendationRowId] = useState<ID>();

  // Call group update API only if multiple recons. Replace TBH case BE logic is heavily simplified if replace recon calls are made to PATCH /reconciliations/${reconId} instead of /grouped-reconciliations.
  const checkIfGroupUpdate = (listUpdate: ListUpdate) => {
    return (listUpdate?.ids ?? []).length > 1;
  };

  const handleIgnore = () => {
    setDisplayIdx();

    listUpdateActionMutation.mutate({
      isGroupUpdate: checkIfGroupUpdate(listUpdate),
      updateId: listUpdate.id,
      request: { action: ListUpdateActionType.Reject, ids: listUpdate.ids },
    });
    Analytics.track('Recon cards discarded', {
      category: 'Lists',
    });
  };

  const handleAccept = async () => {
    const request: ListUpdateRequest = {
      action: ListUpdateActionType.Accept,
      ids: listUpdate.ids,
      listRowId: pickedRecommendationRowId,
    };

    if (listUpdate.status === EntityStatus.Active) {
      request.createNewRow = listUpdate.cardType === ListUpdateType.Create && !isRecommendation;

      // For grouped recon and update case, set create row as true to create new row with new position.
      if (listUpdate.ids?.length === 2 && listUpdate.cardType === ListUpdateType.Update) {
        request.createNewRow = true;
      }

      if (!isEmpty(listUpdate.ids) && doOlderReconsExist(listUpdate)) {
        notification.error({
          message: intl.formatMessage({ id: 'lists.reconciliation.accept_old_cards' }),
        });

        return;
      }
    }

    setDisplayIdx();

    await listUpdateActionMutation.mutateAsync({
      isGroupUpdate: checkIfGroupUpdate(listUpdate),
      updateId: listUpdate.id,
      request,
    });

    setIsRecommendation(false);
    setPickedRecommendationRowId(undefined);
    Analytics.track('Recon cards accepted', {
      category: 'Lists',
    });
  };

  const cardColor = getCardColor({ action: listUpdate?.cardType, isRecommendation });

  useEffect(() => {
    setIsRecommendation(false);
    setPickedRecommendationRowId(undefined);
  }, [listUpdate]);

  return (
    <CardHighlight color={cardColor}>
      <Wrapper>
        <LeftSection
          isRecommendation={isRecommendation}
          listUpdate={listUpdate}
          pickedRecommendationRowId={pickedRecommendationRowId}
          onPickRecommendation={setPickedRecommendationRowId}
        />
        <RightSection
          isRecommendation={isRecommendation}
          listUpdate={listUpdate}
          onAccept={handleAccept}
          onClickLinkButton={() => setIsRecommendation((state) => !state)}
          onIgnore={handleIgnore}
        />
      </Wrapper>
    </CardHighlight>
  );
};
