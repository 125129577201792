import { type Currency } from 'data/currencies';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { type DataFormatType } from 'data/metrics';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import {
  BlankValueType,
  DataFormattingType,
  DisplayUnitType,
  NegativeValueType,
  ZeroFormatType,
} from 'utils/data-formatter/types';
import {
  blankValueOptions,
  displayUnitOptions,
  negativeValueOptions,
  zeroFormatOptions,
} from '../constants';
import { type CustomTypeFormat, type CustomTypeFormatWithCurrency } from '../types';

export const useDataFormatHook = ({
  dataFormat,
  initialType,
}: {
  dataFormat?: DataFormatType;
  initialType?: DataFormattingType;
}): {
  digitsAfterDecimal: number;
  displayUnit: CustomTypeFormatWithCurrency<DisplayUnitType>;
  currency?: Currency;
  zeroFormat: CustomTypeFormat<ZeroFormatType>;
  negativeValueFormat: CustomTypeFormat<NegativeValueType>;
  blankValueFormat: CustomTypeFormat<BlankValueType>;
  fullNumberOption: CustomTypeFormatWithCurrency<DisplayUnitType>;
  type: DataFormattingType;
  allMetricsChecked: boolean;
  handleMetricTypeChange: (type: DataFormattingType) => void;
  handleCurrencyChange: (currency: Currency) => void;
  setBlankValueFormat: Dispatch<SetStateAction<CustomTypeFormat<BlankValueType>>>;
  setDigitsAfterDecimal: Dispatch<SetStateAction<number>>;
  setDisplayUnit: Dispatch<SetStateAction<CustomTypeFormatWithCurrency<DisplayUnitType>>>;
  setZeroFormat: Dispatch<SetStateAction<CustomTypeFormat<ZeroFormatType>>>;
  setNegativeValueFormat: Dispatch<SetStateAction<CustomTypeFormat<NegativeValueType>>>;
  setAllMetricsChecked: Dispatch<SetStateAction<boolean>>;
} => {
  const tenantCurrency = useCurrency();

  const [allMetricsChecked, setAllMetricsChecked] = useState(false);
  const fullNumberOption =
    displayUnitOptions.find((opt) => opt.value === DisplayUnitType.Full) || displayUnitOptions[0];

  const initialDisplayUnit =
    displayUnitOptions.find((opt) => dataFormat && dataFormat.displayUnit === opt.value) ||
    fullNumberOption;

  const initialDigitsAfterDecimal =
    dataFormat?.digitsAfterDecimal ?? (initialType === DataFormattingType.Percentage ? 2 : 0);

  const initialZeroFormat =
    zeroFormatOptions.find((opt) => dataFormat && dataFormat.zeroFormat === opt.value) ||
    zeroFormatOptions[1];

  const initialNegativeValueFormat =
    negativeValueOptions.find(
      (opt) => dataFormat && opt.value === dataFormat.negativeValueFormat,
    ) || negativeValueOptions[1];

  const initialBlankValueFormat =
    blankValueOptions.find((opt) => dataFormat && opt.value === dataFormat.blankValueFormat) ||
    blankValueOptions[1];

  const initialCurrency = dataFormat?.currency ?? tenantCurrency;

  const initialMetricType = initialType || DataFormattingType.Number;

  const [type, setType] = useState<DataFormattingType>(initialMetricType);
  const [displayUnit, setDisplayUnit] = useState(initialDisplayUnit);
  const [digitsAfterDecimal, setDigitsAfterDecimal] = useState(initialDigitsAfterDecimal);
  const [zeroFormat, setZeroFormat] = useState(initialZeroFormat);
  const [negativeValueFormat, setNegativeValueFormat] = useState(initialNegativeValueFormat);
  const [blankValueFormat, setBlankValueFormat] = useState(initialBlankValueFormat);
  const [currency, setCurrency] = useState<Currency | undefined>(initialCurrency);

  const handleMetricTypeChange = (type: DataFormattingType) => {
    setType(type);

    setDigitsAfterDecimal(0);

    if (type === DataFormattingType.Percentage) {
      setDisplayUnit(fullNumberOption);
      setDigitsAfterDecimal(2);
      setCurrency(undefined);
    } else if (type === DataFormattingType.Number) {
      setCurrency(undefined);
    } else if (type === DataFormattingType.Currency) {
      setCurrency(currency || initialCurrency);
    }
  };

  const handleCurrencyChange = (currency: Currency) => {
    if (!currency) {
      return;
    }

    setCurrency(currency);
  };

  useEffect(() => {
    setType(initialType || DataFormattingType.Number);

    setDisplayUnit(
      displayUnitOptions.find(
        (opt) => opt.value === (dataFormat?.displayUnit || DisplayUnitType.Full),
      ) || displayUnitOptions[0],
    );

    setDigitsAfterDecimal(
      dataFormat?.digitsAfterDecimal ?? (initialType === DataFormattingType.Percentage ? 2 : 0),
    );

    setZeroFormat(
      zeroFormatOptions.find(
        (opt) => opt.value === (dataFormat?.zeroFormat || ZeroFormatType.Dash),
      ) || zeroFormatOptions[1],
    );

    setNegativeValueFormat(
      negativeValueOptions.find(
        (opt) => opt.value === (dataFormat?.negativeValueFormat || NegativeValueType.Brackets),
      ) || negativeValueOptions[1],
    );

    setBlankValueFormat(
      blankValueOptions.find(
        (opt) => opt.value === (dataFormat?.blankValueFormat || BlankValueType.Blank),
      ) || blankValueOptions[1],
    );
  }, [dataFormat, initialType]);

  return {
    digitsAfterDecimal,
    displayUnit,
    currency,
    zeroFormat,
    negativeValueFormat,
    blankValueFormat,
    type,
    fullNumberOption,
    allMetricsChecked,
    handleMetricTypeChange,
    handleCurrencyChange,
    setBlankValueFormat,
    setDigitsAfterDecimal,
    setDisplayUnit,
    setZeroFormat,
    setNegativeValueFormat,
    setAllMetricsChecked,
  };
};
export { CustomTypeFormatWithCurrency };
