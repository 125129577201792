import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ListsApi } from '../api';

export const useListTriggerDatasetBuildMutation = (): UseMutationResult<void, unknown, void> => {
  const intl = useIntl();

  return useMutation({
    mutationFn: () => ListsApi.triggerDatasetBuild(),
    onSuccess: () => {
      notification.success({
        message: intl.formatMessage({ id: 'lists.trigger_dataset_build.success' }),
      });
    },
    onError: defaultApiErrorHandler,
  });
};
