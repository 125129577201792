import { Empty } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getAssetUrl } from 'utils';
import { NewListButton } from '../page-header/new-list-button';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme: { font } }) => font.text};
  color: ${({ theme: { colors } }) => colors.textBody1};
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledEmpty = styled(Empty)`
  .ant-empty-footer {
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
`;

const ImageTag = styled.img``;

export const EmptyLists: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Wrapper>
      <Content>
        <StyledEmpty
          description={<FormattedMessage id="lists.new_text" />}
          image={<ImageTag src={getAssetUrl('v2/empty/create-list-placeholder.webp')} />}
          title={<FormattedMessage id="lists.empty.title" />}
        >
          <NewListButton />
        </StyledEmpty>
      </Content>
    </Wrapper>
  );
};
