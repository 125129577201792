import axios from 'axios';
import { type ID } from 'data';
import { type VisualQueryFilterRule, type QueryTableColumnDataType } from 'data/big-query';
import { type DimensionQueryParamsObject } from 'data/dimension';
import { type CSVListPreviewData, type CSVPreviewRowItem } from 'data/lists';
import { omit } from 'lodash';
import { stringify } from 'query-string';
import { formatName } from 'utils/data-formatter';
import {
  type RowStructure,
  type List,
  type ListRow,
  type RowReorderRequest,
  type ListUpdate,
  type ListUpdateRequest,
  type ReconciliationConfig,
} from './types';

const API_PATH = '/v1/lists';

export const ListsApi = {
  /**
   * Get all lists
   */
  async findAll(): Promise<List[]> {
    const { data } = await axios.get(API_PATH);

    return data;
  },

  /**
   * Create a list
   */
  async createList(list: Partial<List>): Promise<List> {
    const { data } = await axios.post(API_PATH, list);

    return data;
  },

  /**
   * Get a particular list
   */
  async findList(
    listId: number,
    params?: {
      filters?: VisualQueryFilterRule[];
      boardFilters?: DimensionQueryParamsObject;
    },
  ): Promise<List> {
    const { data } = await axios.post(`${API_PATH}/${listId}`, params);

    return data;
  },

  /**
   * Edit a particular list
   */
  async editList(listId: number, listBody: Partial<List>): Promise<void> {
    const { data } = await axios.patch(`${API_PATH}/${listId}`, listBody);

    return data;
  },

  /**
   * Bulk paste
   */
  async bulkPaste(listId: number, rows: CSVPreviewRowItem[]): Promise<CSVPreviewRowItem[]> {
    const cleanRows = rows?.map((r) => {
      if ((r.rowId && r.rowId < 0) || !r.rowId) {
        return omit(r, ['rowId', 'position']);
      }

      return r;
    });
    const { data } = await axios.post(`${API_PATH}/${listId}/bulk-paste`, cleanRows);

    return data;
  },

  async getListRowValues(listId: number): Promise<Record<string, string[]>> {
    const { data } = await axios.get(`${API_PATH}/${listId}/values`);

    return data;
  },

  /**
   * Delete a particular list
   */
  async deleteList(id: number): Promise<void> {
    return axios.delete(`${API_PATH}/${id}`);
  },

  /**
   * Adds a row to list
   * @param listId ID of the list
   * @param rowStructure Row to be created - can be optional
   * @returns A void response
   */
  async addRow(listId: number, rowStructure?: Partial<ListRow>): Promise<ListRow> {
    const { data } = await axios.post(`${API_PATH}/${listId}/rows`, rowStructure);

    return data;
  },

  /**
   * Updates a specific row
   * @param listId ID of the list
   * @param rowStructure Row to be updated
   * @returns A void response
   */
  async updateRow(listId: number, rowStructure: Partial<ListRow>): Promise<Partial<ListRow>> {
    const { data } = await axios.patch(`${API_PATH}/${listId}/rows`, rowStructure);

    return data;
  },

  /**
   * Reorders rows in the list
   * @param listId ID of the list
   * @param rowStructure Row to be updated
   * @returns A void response
   */
  async reorderRows(listId: number, rowReorderRequestBody: RowReorderRequest): Promise<ListRow[]> {
    const { data } = await axios.post(`${API_PATH}/${listId}/rows/re-order`, rowReorderRequestBody);

    return data.map(
      (row: { id: ID; listId: ID; backendId: ID; data: RowStructure; position: number }) => ({
        id: row.id,
        listId: row.listId,
        data: row.data,
        position: row.position,
        backendId: row.id,
      }),
    );
  },

  /**
   * Deletes a row
   * @param listId ID of the list
   * @param rowStructure Row to be deleted
   * @returns A void response
   */
  async deleteRow(listId: number, rowIds: number[]): Promise<void> {
    const { data } = await axios.delete(`${API_PATH}/${listId}/rows`, { data: rowIds });

    return data;
  },

  /**
   * Adds a column
   * @param listId ID of the list
   * @param columnName Name of the column
   * @param type BQ type
   * @returns Nothing
   */
  async addColumn(
    listId: number,
    columnName: string,
    type?: QueryTableColumnDataType,
    dateFormat?: string,
    formula?: string,
    query?: string,
  ): Promise<{ name: string }> {
    const columnAttributes = {
      columnName,
      type,
      dateFormat,
      formula,
      query,
    };

    const { data } = await axios.post(`${API_PATH}/${listId}/columns`, columnAttributes);

    return data;
  },

  /**
   * Updates a column
   * @param listId ID of the list
   * @param newColumnName New name of the column
   * @param columnName Old name of the column
   * @param type BQ type
   * @returns Nothing
   */
  async updateColumn({
    listId,
    newColumnName,
    columnName,
    type,
    formula,
    dateFormat,
    query,
  }: {
    listId: number;
    newColumnName?: string;
    columnName: string;
    type?: QueryTableColumnDataType;
    formula?: string;
    dateFormat?: string;
    query?: string;
  }): Promise<{ name: string }> {
    const columnAttributes = {
      columnName,
      newColumnName,
      type,
      formula,
      dateFormat,
      query,
    };

    const { data } = await axios.patch(`${API_PATH}/${listId}/columns`, columnAttributes);

    return data;
  },

  /**
   * Removes a column
   * @param listId ID of the list
   * @param columnName Name of the column
   * @param type BQ type
   * @returns Nothing
   */
  async deleteColumn(
    listId: number,
    columnName: string,
    type?: QueryTableColumnDataType,
  ): Promise<void> {
    const columnAttributes = {
      columnName,
      type,
    };

    const { data } = await axios.delete(`${API_PATH}/${listId}/columns`, {
      data: columnAttributes,
    });

    return data;
  },

  async uploadListsCSV(listId: number, formData: FormData): Promise<CSVListPreviewData> {
    const { data } = await axios.post(`${API_PATH}/${listId}/upload`, formData);

    return data;
  },

  async uploadAndValidateListsCSV(listId: number, formData: FormData): Promise<CSVListPreviewData> {
    const { data } = await axios.post(`${API_PATH}/${listId}/upload-and-validate`, formData);

    return data;
  },

  async validateListCSVContent(
    listId: number,
    content: CSVListPreviewData,
  ): Promise<CSVListPreviewData> {
    const { data } = await axios.post(`${API_PATH}/${listId}/validate`, content);

    return data;
  },

  async saveListCSVContent(
    listId: number,
    content: CSVListPreviewData,
  ): Promise<{ success?: boolean }> {
    const { data } = await axios.post(`${API_PATH}/${listId}/save`, content);

    return data;
  },

  downloadListPreviewData: (listId: number, previewData: CSVPreviewRowItem[]): Promise<void> => {
    const apiPath = `${API_PATH}/${listId}/download/excel`;

    return axios
      .post(apiPath, previewData, { responseType: 'blob', data: previewData })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${response.headers.filename}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  },

  async search(
    queryString: string,
  ): Promise<{ value: string; label: string; parentSourceDisplayName: string }[]> {
    const { data } = await axios.get('/v1/search/dimensions', {
      params: { q: queryString },
      paramsSerializer: (params) => stringify(params),
    });

    return data.map((item: { fullDimensionName: string; tableDisplayName: string }) => ({
      value: item.fullDimensionName,
      label: formatName(item.fullDimensionName),
      parentSourceDisplayName: item.tableDisplayName,
    }));
  },

  async setAccessControl({
    listId,
    listName,
    isAccessControlled,
    roleIds,
  }: {
    listId: number;
    listName: string;
    isAccessControlled: boolean;
    roleIds: number[];
  }): Promise<void> {
    const { data } = await axios.post(`${API_PATH}/${listId}/control-access`, {
      name: listName,
      accessControlled: isAccessControlled,
      fullAccessRoleIds: roleIds,
    });

    return data;
  },

  /**
   * List updates
   */

  async getListUpdates(listId: number): Promise<ListUpdate[]> {
    const { data } = await axios.get(`${API_PATH}/${listId}/reconciliations`);

    return data;
  },

  async takeActionOnListUpdate(
    listId: ID,
    updateId: ID,
    request: ListUpdateRequest,
  ): Promise<ListUpdate> {
    const { data } = await axios.patch(
      `${API_PATH}/${listId}/reconciliations/${updateId}`,
      request,
    );

    return data;
  },

  async takeActionOnGroupListUpdate(listId: ID, request: ListUpdateRequest): Promise<ListUpdate> {
    const { data } = await axios.patch(`${API_PATH}/${listId}/grouped-reconciliations`, request);

    return data;
  },

  async getListReconMatchOptions(listId: number): Promise<ListRow[]> {
    const { data } = await axios.get(`${API_PATH}/${listId}/reconciliations/match-options`);

    return data;
  },

  async setupDerivedListRecon({
    listId,
    request,
  }: {
    listId: number;
    request: Partial<ReconciliationConfig>;
  }): Promise<void> {
    const { data } = await axios.post(`${API_PATH}/${listId}/derived-list-recon`, request);

    return data;
  },

  async triggerDatasetBuild(): Promise<void> {
    const { data } = await axios.post(`${API_PATH}/dbt`);

    return data;
  },

  async editDerivedListRecon({
    listId,
    request,
  }: {
    listId: number;
    request: Partial<ReconciliationConfig>;
  }): Promise<void> {
    const { data } = await axios.patch(`${API_PATH}/${listId}/derived-list-recon`, request);

    return data;
  },

  async setupListRecon({
    listId,
    request,
  }: {
    listId: number;
    request: Partial<ReconciliationConfig>;
  }): Promise<void> {
    const { data } = await axios.post(`${API_PATH}/${listId}/list-recon`, request);

    return data;
  },

  async editListRecon({
    listId,
    request,
  }: {
    listId: number;
    request: Partial<ReconciliationConfig>;
  }): Promise<void> {
    const { data } = await axios.patch(`${API_PATH}/${listId}/list-recon`, request);

    return data;
  },

  async cleanupListRecon({ listId }: { listId: number }): Promise<void> {
    const { data } = await axios.delete(`${API_PATH}/${listId}/list-recon`);

    return data;
  },
};
