import { LeftNavLayout } from 'components/ui/layout/left-nav-layout-v2';
import { useLeftNavResize } from 'components/ui/layout/left-nav-layout-v2/use-left-nav-resize';
import { useLeftNavStore } from 'components/ui/layout/left-nav-store';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { type ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LeftNav } from './left-nav';
import { RightSectionList } from './right-section';
import { RightSectionDimensionGroup } from './right-section-dim-group';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme: { font } }) => font.text};
  color: ${({ theme: { colors } }) => colors.textBody1};
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ListsEditorModule = (): ReactElement => {
  const { listId } = useParams() as { listId: string };

  const isDimGroup = listId.startsWith(DIM_GROUP_ID_PREFIX);

  const showSecondaryLeftNav = useLeftNavStore((state) => state.showSecondaryLeftNav);

  const { onResize: onLeftNavResize, currentWidth: currentLeftNavWidth } = useLeftNavResize('list');

  return (
    <Wrapper>
      <Content>
        <LeftNavLayout>
          <LeftNavLayout.ResizableLeftPanel
            $hide={!showSecondaryLeftNav}
            defaultWidth={currentLeftNavWidth}
            onPanelResize={onLeftNavResize}
          >
            <LeftNav />
          </LeftNavLayout.ResizableLeftPanel>

          {showSecondaryLeftNav && <LeftNavLayout.ResizeHandle />}

          <LeftNavLayout.ResizableRightPanel>
            {!isDimGroup && <RightSectionList key={listId} />}

            {isDimGroup && <RightSectionDimensionGroup />}
          </LeftNavLayout.ResizableRightPanel>
        </LeftNavLayout>
      </Content>
    </Wrapper>
  );
};
