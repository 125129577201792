import { ReactComponent as DriveAiIcon } from 'assets/v2/drive_ai_24.svg';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LeftNavWrapper } from '../left-nav-item';
import { ChatBox } from './chatbox';

const Wrapper = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AiChatBoxButton = (): ReactElement => {
  const { driveAiChatbox } = useFlags();

  const [isChatOpen, setIsChatOpen] = useState(false);

  const onClick = () => {
    setIsChatOpen(!isChatOpen);
  };

  if (!driveAiChatbox) {
    return <></>;
  }

  return (
    <>
      <LeftNavWrapper onClick={onClick}>
        <Wrapper>
          <IconShell icon={DriveAiIcon} />
          <span>
            <FormattedMessage id="drive_ai.title" />
          </span>
        </Wrapper>
      </LeftNavWrapper>

      <ChatBox isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </>
  );
};
