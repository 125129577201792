import { create } from 'zustand';

interface TemplateStore {
  code: string;
  actions: {
    setCode: (code: string) => void;
  };
}

const useTemplateStore = create<TemplateStore>()((set) => ({
  code: '',
  actions: {
    setCode: (code: string) => {
      set({ code });
    },
  },
}));

// State
export const useTemplateCode = (): TemplateStore['code'] => useTemplateStore((state) => state.code);

// Actions
export const useTemplateActions = (): TemplateStore['actions'] =>
  useTemplateStore((state) => state.actions);
