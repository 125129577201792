import { AsyncSwitcher } from 'components/ui/async-switcher';
import { useDimensionGroup } from 'data/modelling/dimension-group/hooks/use-dimension-group';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { useLists } from 'data/modelling/lists';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { isEmpty } from 'lodash';
import { useState, type ReactElement, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useListStoreContext } from 'store/lists';
import { ListGrid } from '../right-section/grid';
import { loadLocalData, saveLocalData } from '../right-section/utils';
import { styles } from './styles';
import { DimensionGroupTopNav } from './top-nav';

const { Wrapper, RightSection, GridWrapper } = styles;

export const RightSectionDimensionGroup = (): ReactElement => {
  const { listId } = useParams() as { listId: string };
  const dimGroupId = Number(listId?.split('-')?.[1]);

  const dimensionGroupQuery = useDimensionGroup(dimGroupId);
  const { data: lists } = useLists();

  const initializeStore = useListStoreContext((s) => s.initializeStore);
  const initializeLocalData = useListStoreContext((s) => s.initializeLocalData);

  const [hasStoreInitialized, setHasStoreInitialized] = useState(false);

  const dimensionGroupItem = useMemo(() => {
    if (!isEmpty(lists) && dimGroupId) {
      return lists?.find(
        (l) => (l as DimensionGroup).id === dimGroupId && (l as DimensionGroup).uniqueKeyColumns,
      );
    }

    return undefined;
  }, [lists, dimGroupId]);

  useEffect(() => {
    const data = dimensionGroupQuery?.data;

    if (data) {
      initializeStore({ data, embedPlace: 'global-list' });

      loadLocalData(`${DIM_GROUP_ID_PREFIX}${data.id}`).then((localData) => {
        initializeLocalData(localData as { columnWidthMap: Record<string, number> });
        setHasStoreInitialized(true);
      });
    }
  }, [dimensionGroupQuery?.data, initializeLocalData, initializeStore]);

  return (
    <Wrapper>
      <RightSection>
        {dimensionGroupItem && (
          <DimensionGroupTopNav dimensionGroup={dimensionGroupItem as DimensionGroup} />
        )}

        <AsyncSwitcher {...dimensionGroupQuery} isLoading={dimensionGroupQuery.isLoading}>
          {() =>
            hasStoreInitialized && (
              <>
                <GridWrapper>
                  <ListGrid
                    onColumnWidthSave={({ updatedColumnWidthMap }) =>
                      saveLocalData({
                        activeListId: listId,
                        updatedColumnWidthMap,
                      })
                    }
                  />
                </GridWrapper>
              </>
            )
          }
        </AsyncSwitcher>
      </RightSection>
    </Wrapper>
  );
};
