import { type DropDownProps } from 'antd';
import { ReactComponent as ChevronRightIcon } from 'assets/v2/chevron-right.svg';
import { ReactComponent as MoreOptionsIcons } from 'assets/v2/dots-vertical.svg';
import { isEmpty } from 'lodash';
import { type MouseEvent, type ReactElement } from 'react';
import { Button } from '../button';
import { IconShell, type SvgIconColor } from '../icon-shell';
import { type MenuProps } from '../menu';
import { Dropdown } from './dropdown';

interface MoreActionsDropdownProps extends Omit<DropDownProps, 'menu'> {
  menu?: MenuProps;
  size?: 'extraSmall' | 'small' | 'middle' | 'large' | 'extraLarge';
  iconColor?: SvgIconColor;
  onButtonClick?: (e: MouseEvent) => void;
}
export const MoreActionsDropdown = ({
  size = 'middle',
  iconColor,
  onButtonClick,
  menu,
  ...props
}: MoreActionsDropdownProps): ReactElement => {
  const visibleMenuItems = (menu?.items || []).filter((item) => !item.hidden);

  if (isEmpty(visibleMenuItems)) {
    return <></>;
  }

  return (
    <Dropdown
      trigger={['click']}
      {...props}
      menu={{
        expandIcon: (
          <>
            {/** If the empty fragment is removed it causes the arrow to show on top of text */}
            <IconShell icon={ChevronRightIcon} />
          </>
        ),
        ...menu,
      }}
    >
      <Button
        data-exclude-in-pdf
        icon={<IconShell color={iconColor} icon={MoreOptionsIcons} />}
        size={size}
        type="text"
        onClick={onButtonClick}
      />
    </Dropdown>
  );
};
