import Bugsnag from '@bugsnag/js';
import { Button } from 'components/ui/atomic-components';
import { type ModelTemplate } from 'data/templates';
import { useTemplateMutations } from 'data/templates/hooks/use-template-mutations';
import Cookies from 'js-cookie';
import { useEffect, useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTemplateActions, useTemplateCode } from 'store/templates';
import styled from 'styled-components';
import { MoreActions } from '../model-templates/template-content/page-header/more-actions';
import {
  PREVIEW_MODEL_TEMPLATE,
  VERSION_ACCESS_COOKIE_KEY,
  VERSION_MODE_COOKIE_KEY,
} from './constants';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
  align-items: center;

  .ant-dropdown-trigger:not(:disabled):not(:active):hover {
    background: none;
  }

  .ant-btn-icon svg {
    color: ${({ theme }) => theme.colors.gray00};

    &:hover {
      color: ${({ theme }) => theme.colors.gray600};
    }
  }
`;

const UseTemplateButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.orange600};
  border: none;
  color: ${({ theme }) => theme.colors.gray00};

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: ${({ theme }) => theme.colors.orange500};
    color: ${({ theme }) => theme.colors.gray00};
  }
`;

interface Props {
  label: string;
  previewModelTemplate?: ModelTemplate;
}

export const BannerTitle = ({ label, previewModelTemplate }: Props): ReactElement => {
  const { applyTemplateMutation } = useTemplateMutations();
  const [isLoading, setIsLoading] = useState(false);
  const code = useTemplateCode();
  const { setCode } = useTemplateActions();

  useEffect(() => {
    if (previewModelTemplate) {
      setCode(JSON.stringify(previewModelTemplate.params, undefined, 4));
    }
  }, [previewModelTemplate, setCode]);

  const handleUseTemplate = async () => {
    if (!previewModelTemplate) {
      return;
    }

    setIsLoading(true);
    try {
      await applyTemplateMutation.mutateAsync({
        ...previewModelTemplate,
        params: JSON.parse(code) || '',
      });
    } catch {
      Bugsnag.notify('Apply preview template failed');
      setIsLoading(false);
    }

    Cookies.remove(VERSION_MODE_COOKIE_KEY);
    Cookies.remove(VERSION_ACCESS_COOKIE_KEY);
    Cookies.remove(PREVIEW_MODEL_TEMPLATE);
  };

  return (
    <Wrapper>
      {label}
      {previewModelTemplate && (
        <UseTemplateButton loading={isLoading} size="extraSmall" onClick={handleUseTemplate}>
          <FormattedMessage id="model.templates.content.header.use_template" />
        </UseTemplateButton>
      )}
      <MoreActions iconColor="white" size="extraSmall" />
    </Wrapper>
  );
};
