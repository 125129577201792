import { ReactComponent as CheckIcon } from 'assets/v2/check_12.svg';
import { ReactComponent as RefreshIcon } from 'assets/v2/refresh_12.svg';
import { IconShell, Tooltip } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled, { css } from 'styled-components';
import { FontXs } from 'styles/typography';
import { useListUpdates } from '../right-section/list-updates/hooks/use-list-updates';

const Wrapper = styled.div<{ hasUpdates?: boolean }>`
  ${FontXs};

  display: flex;
  align-items: center;
  height: 20px;
  padding: ${({ theme: { spacing } }) => `${spacing[2]} ${spacing[8]} ${spacing[2]} ${spacing[6]}`};
  gap: ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.borderRadius.xxl};

  ${({ hasUpdates }) => {
    if (hasUpdates) {
      return css`
        cursor: pointer;
        background: ${({ theme }) => theme.colors.bgPrimaryLight};
        color: ${({ theme }) => theme.colors.textPrimary};
      `;
    }

    return css`
      background: ${({ theme }) => theme.colors.bgMedium};
      color: ${({ theme }) => theme.colors.textBody2};
    `;
  }}
`;

export const ReconUpdatesPill = (): ReactElement => {
  const { data: listUpdates, isLoading } = useListUpdates();

  const showReconAlert = useListStoreContext((s) => s.showReconAlert);
  const setShowReconAlert = useListStoreContext((s) => s.setShowReconAlert);

  if (isLoading) {
    return <></>;
  }

  if (!listUpdates?.length) {
    return (
      <Tooltip title={<FormattedMessage id="lists.reconciliation.title_pill.tooltip" />}>
        <Wrapper>
          <IconShell icon={CheckIcon} />
          <span>
            <FormattedMessage id="lists.reconciliation.title_pill.updated" />
          </span>
        </Wrapper>
      </Tooltip>
    );
  }

  if (showReconAlert) {
    return <></>;
  }

  return (
    <Wrapper hasUpdates onClick={() => setShowReconAlert(true)}>
      <IconShell icon={RefreshIcon} />
      <span>
        <FormattedMessage
          id="lists.reconciliation.title_pill.update_count"
          values={{ count: listUpdates?.length }}
        />
      </span>
    </Wrapper>
  );
};
