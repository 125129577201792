import { ReactComponent as UserShieldIcon } from 'assets/v2/user-shield_12.svg';
import { ReactComponent as WorldIcon } from 'assets/v2/world_12.svg';
import { IconShell, Tooltip } from 'components/ui/atomic-components';
import { isNil } from 'lodash';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { FontS, SingleLineEllipsis } from 'styles/typography';

const Wrapper = styled.div`
  ${FontS};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing[4]};
  margin-top: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.textTitle1};

  svg {
    flex-shrink: 0;
  }

  span {
    ${SingleLineEllipsis};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.gray200};
  top: 10px;
  position: relative;
`;

export const AccessVisibilityInfo = (): ReactElement => {
  const visibleToEveryone = useListStoreContext((s) => s.config.visibleToEveryone);

  if (isNil(visibleToEveryone)) {
    return <></>;
  }

  if (visibleToEveryone) {
    return (
      <>
        <Tooltip title={<FormattedMessage id="lists.access_visibility.everyone.tooltip" />}>
          <Wrapper>
            <IconShell color="textBody2" icon={WorldIcon} />
            <span>
              <FormattedMessage id="lists.access_visibility.everyone" />
            </span>
          </Wrapper>
        </Tooltip>

        <Divider />
      </>
    );
  }

  return (
    <>
      <Tooltip title={<FormattedMessage id="lists.access_visibility.restricted.tooltip" />}>
        <Wrapper>
          <IconShell color="textBody2" icon={UserShieldIcon} />
          <span>
            <FormattedMessage id="lists.access_visibility.restricted" />
          </span>
        </Wrapper>
      </Tooltip>

      <Divider />
    </>
  );
};
