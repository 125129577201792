import { type TextOptionType } from './types';

export const getSelectedOptions = (value: string): TextOptionType | null => {
  if (!value) {
    return null;
  }

  return {
    label: value,
    value,
  };
};

export const filterOption = ({
  inputValue,
  option,
  isSelectiveAccessColumn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any;
  inputValue: string;
  isSelectiveAccessColumn: boolean;
}): boolean => {
  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
  const isCreateOption = (option?.data as any).__isNew__;

  if (isCreateOption && isSelectiveAccessColumn) {
    return false;
  }

  return true;
};

export const generateInitialValue = (eventKey: string | null): string | undefined => {
  if (eventKey) {
    if (eventKey !== 'Enter') {
      return String(eventKey);
    }
  }
};
