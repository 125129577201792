import { ReactComponent as ChevronDownIcon } from 'assets/v2/chevron-down.svg';
import { Menu, IconShell, IconText, Popover, type ItemType } from 'components/ui/atomic-components';
import { QueryTableColumnDataType } from 'data/big-query';
import { formatTransformer } from 'data/date-format/utils/formatted-date';
import { type FC, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';
import { type AllowedTypes, IconMapper, type ColumnType, LabelKeyMapper } from './utils';

const MenuWrapper = styled.div`
  .ant-menu-root {
    background: ${({ theme }) => theme.colors.gray00};
    width: 200px;
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    box-shadow: ${({ theme }) => theme.shadow.softMicro};
  }
`;

const StyledMenu = styled(Menu)`
  &.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
  }

  .ant-menu-item {
    height: 28px;
    display: flex;
    align-items: center;

    .ant-menu-title-content {
      width: 100%;
    }
  }
`;

const ControlItem = styled.div<{ $disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.gray00};
  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[12]}`};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  cursor: pointer;

  :hover {
    border-color: ${({ theme, $disabled }) =>
      `${$disabled ? theme.colors.gray200 : theme.colors.primary500}`};
  }

  ${({ $disabled }) => {
    if ($disabled) {
      return css`
        color: ${({ theme }) => theme.colors.textDisabled};
        background: ${({ theme }) => theme.colors.gray100};
        cursor: default;
      `;
    }
  }}
`;

const StyledIconText = styled(IconText)`
  ${SingleLineEllipsis}
`;

export const ColumnTypeSelector: FC<
  React.PropsWithChildren<{
    value?: ColumnType;
    onChange?: (selection: ColumnType) => void;
    disabled: boolean;
    columnOptions: ItemType[];
  }>
> = ({ value, onChange, disabled, columnOptions }) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const onSelectOption = (opt: ItemType) => {
    if (String(opt.key).startsWith('df~')) {
      // date format change
      onChange?.({
        key: QueryTableColumnDataType.Date,
        dateFormat: String(opt.key).replace('df~', ''),
      });
    } else {
      // column type change
      onChange?.(opt);
    }
    setOpen(false);
  };

  const labelExt =
    value?.key === QueryTableColumnDataType.Date && value.dateFormat
      ? ` (${formatTransformer(value.dateFormat)})`
      : '';

  return (
    <Popover
      content={
        <MenuWrapper>
          <StyledMenu items={columnOptions as ItemType[]} onClick={onSelectOption} />
        </MenuWrapper>
      }
      open={open}
      overlayClassName="overlay-xyz"
      placement="bottomLeft"
      trigger={disabled ? [] : ['click']}
      onOpenChange={(s) => setOpen(s)}
    >
      <ControlItem $disabled={disabled}>
        <StyledIconText
          color="iconDefault"
          icon={IconMapper[value?.key as AllowedTypes]}
          text={intl.formatMessage({ id: LabelKeyMapper[value?.key as AllowedTypes] }) + labelExt}
        />
        <IconShell color="gray400" icon={ChevronDownIcon} />
      </ControlItem>
    </Popover>
  );
};
