import { ConfirmationModal } from 'components/ui/atomic-components';
import { Analytics } from 'config/analytics';
import { type ListUpdate } from 'data/modelling/lists';
import { isEmpty } from 'lodash';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { useListUpdateActionMutation } from './hooks/use-list-update-action-mutation';
import { useListUpdates } from './hooks/use-list-updates';
import { Navigator } from './navigator';
import { TitleSection } from './title-section';
import { UpdateActionCard } from './update-action-card';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[8]};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  margin: ${({ theme }) => theme.spacing[16]};
  gap: ${({ theme }) => theme.spacing[8]};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  max-height: 500px;
  overflow: auto;
`;

export const ListUpdates = (): ReactElement => {
  const { data: listUpdates = [] } = useListUpdates();
  const listUpdateActionMutation = useListUpdateActionMutation();

  const [showAllUpdates, setShowAllUpdates] = useState(false);
  const [listUpdateOnDisplayIdx, setListUpdateOnDisplayIdx] = useState(0);

  const showReconAlert = useListStoreContext((s) => s.showReconAlert);
  const setShowReconAlert = useListStoreContext((s) => s.setShowReconAlert);
  const { reconEntityId = [] } = useListStoreContext((s) => s.reconciliationConfig) ?? {};

  const navigateToNext = () => {
    if (listUpdateOnDisplayIdx < listUpdates.length - 1) {
      setListUpdateOnDisplayIdx((state) => state + 1);
    }
  };

  const navigateToPrevious = () => {
    if (listUpdateOnDisplayIdx > 0) {
      setListUpdateOnDisplayIdx((state) => state - 1);
    }
  };

  const setDisplayIdx = () => {
    if (listUpdateOnDisplayIdx >= listUpdates.length - 1) {
      navigateToPrevious();
    }
  };

  const doOlderReconsExist = (listUpdate: ListUpdate) => {
    if (!isEmpty(reconEntityId)) {
      let isReconFound = false;

      for (let i = listUpdates.length - 1; i >= 0; i -= 1) {
        const currListUpdate = listUpdates[i];

        if (listUpdate.id === currListUpdate.id) {
          isReconFound = true;
          continue;
        }

        if (
          isReconFound &&
          currListUpdate.data[reconEntityId[0]] === listUpdate.data[reconEntityId[0]]
        ) {
          return true;
        }
      }
    }

    return false;
  };

  if (isEmpty(listUpdates) || !showReconAlert) {
    return <></>;
  }

  return (
    <Wrapper>
      <TitleSection onClose={() => setShowReconAlert(false)} />

      <UpdateActionCard
        doOlderReconsExist={doOlderReconsExist}
        listUpdate={listUpdates[listUpdateOnDisplayIdx]}
        listUpdateActionMutation={listUpdateActionMutation}
        setDisplayIdx={setDisplayIdx}
      />

      <Navigator
        currentIdx={listUpdateOnDisplayIdx}
        maxIdx={listUpdates.length - 1}
        onClickShowAllUpdates={() => {
          setShowAllUpdates(true);
          Analytics.track('Recon cards expanded', {
            category: 'Lists',
          });
        }}
        onNavigateToNext={navigateToNext}
        onNavigateToPrevious={navigateToPrevious}
      />

      <ConfirmationModal
        cancelText={<FormattedMessage id="close" />}
        open={showAllUpdates}
        title={<FormattedMessage id="lists.reconciliation.modal.title" />}
        width={700}
        onCancel={() => setShowAllUpdates(false)}
      >
        <ModalContent>
          {listUpdates.map((listUpdate) => (
            <UpdateActionCard
              key={listUpdate.id}
              doOlderReconsExist={doOlderReconsExist}
              listUpdate={listUpdate}
              listUpdateActionMutation={listUpdateActionMutation}
              setDisplayIdx={setDisplayIdx}
            />
          ))}
        </ModalContent>
      </ConfirmationModal>
    </Wrapper>
  );
};
