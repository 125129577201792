import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ListsApi } from 'data/modelling/lists/api';

export const useListRowValues = ({
  listId,
}: {
  listId: number;
}): UseQueryResult<Record<string, string[]>, unknown> => {
  const listRowValuesQuery = useQuery(
    ['list-row-values', String(listId)],
    () => ListsApi.getListRowValues(listId),
    {
      staleTime: StaleTime.Short,
    },
  );

  return listRowValuesQuery;
};
