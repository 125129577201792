import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { DeLoreanApi } from '../api';
import { type AiPromptResponse } from '../types';

export const useAskPromptMutation = (): UseMutationResult<
  AiPromptResponse,
  unknown,
  {
    message: string;
    convId?: string;
  },
  unknown
> => {
  const useAskPromptMutation = useMutation({
    mutationFn: ({ message, convId }: { message: string; convId?: string }) =>
      DeLoreanApi.askPrompt({ message, convId }),
    onError: (e: AxiosError) => {
      defaultApiErrorHandler(e);
    },
  });

  return useAskPromptMutation;
};
