import { type InputRef } from 'antd';
import { useAskPromptMutation } from 'data/delorian/hooks';
import { AnimatePresence, motion, useAnimate } from 'framer-motion';
import { lazy, Suspense, useCallback, useEffect, useRef, useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { generateRandomNumber } from 'utils';
import { EmptyBlock } from './body/response-block/empty-block';
import { Footer } from './footer';
import { TitleBar } from './title-bar';
import { type AiMessage } from './types';

const LazyChatBody = lazy(() => import('./body'));

const Wrapper = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  position: fixed;
  bottom: 36px;
  right: 36px;
  border-radius: ${({ theme }) => theme.borderRadius.xxl};
  border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  background: ${({ theme }) => theme.colors.gray00};
  box-shadow: ${({ theme }) => theme.shadow.softMedium};
  opacity: 0;
  visibility: hidden;
`;

const BodyWrapper = styled(motion.div)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-direction: column-reverse; /* Col-reverse is used to enable automatic scrolling as content populates the div */
  gap: ${({ theme }) => theme.spacing[20]};
  padding: ${({ theme: { spacing } }) => `${spacing[16]} ${spacing[16]}`};
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ChatBox = ({ isOpen, onClose }: Props): ReactElement => {
  const [messages, setMessages] = useState<AiMessage[]>([]);

  const [chartBodyScope, animateChatBody] = useAnimate();

  const inputRef = useRef<InputRef>(null);
  const messagesDivRef = useRef<HTMLDivElement>(null);
  const convId = useRef<string>();

  const { mutateAsync: askPrompt, isLoading: askPromptIsLoading } = useAskPromptMutation();

  const onMessageSubmit = useCallback(
    (message: string) => {
      scrollToBottom();

      setMessages((prev) => [
        {
          id: generateRandomNumber(),
          markdown: message,
          role: 'user',
        },
        ...prev,
      ]);

      askPrompt({ message, convId: convId.current }).then((response) => {
        convId.current = response.convId;

        setMessages((prev) => [
          {
            id: generateRandomNumber(),
            role: 'system',
            ...response,
          },
          ...prev,
        ]);
      });
    },
    [askPrompt],
  );

  const scrollToBottom = () => {
    if (messagesDivRef.current) {
      messagesDivRef.current.scrollTop = messagesDivRef.current.scrollHeight;
    }
  };

  const restConversation = () => {
    setMessages([]);
    convId.current = undefined;
  };

  useEffect(() => {
    // onclose reset state
    if (!isOpen) {
      animateChatBody(chartBodyScope.current, { opacity: 0, visibility: 'hidden' });
      restConversation();
    } else {
      animateChatBody(chartBodyScope.current, { opacity: 1, visibility: 'visible' });
    }
  }, [animateChatBody, isOpen, chartBodyScope]);

  return (
    <Wrapper ref={chartBodyScope}>
      <Suspense>
        <TitleBar onClose={onClose} onReset={restConversation} />

        <AnimatePresence>
          {isOpen && (
            <BodyWrapper
              ref={messagesDivRef}
              animate={{ height: 510 }}
              exit={{ height: 420 }}
              initial={{ height: 420 }}
              transition={{
                duration: 0.3,
                damping: 20,
                stiffness: 80,
              }}
            >
              {!messages.length && <EmptyBlock />}

              <LazyChatBody messages={messages} onMessageSubmit={onMessageSubmit} />
            </BodyWrapper>
          )}
        </AnimatePresence>

        <Footer
          inputRef={inputRef}
          isLoading={askPromptIsLoading}
          isOpen={isOpen}
          onSubmit={onMessageSubmit}
        />
      </Suspense>
    </Wrapper>
  );
};
