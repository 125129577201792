import { ReactComponent as CodeIcon } from 'assets/v2/code.svg';
import { IconShell, MoreActionsDropdown } from 'components/ui/atomic-components';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { InputJSONModal } from '../input-json-modal';

export const MoreActions = (
  props: React.ComponentProps<typeof MoreActionsDropdown>,
): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <MoreActionsDropdown
        {...props}
        menu={{
          items: [
            {
              key: 'input-json',
              icon: <IconShell icon={CodeIcon} size="md" />,
              label: <FormattedMessage id="model.model_templates.more_actions.input_json" />,
              onClick: () => setShowModal(true),
            },
          ],
        }}
      />
      <InputJSONModal closeModal={() => setShowModal(false)} open={showModal} />
    </>
  );
};
