import { ReactComponent as TargetArrowIcon } from 'assets/v2/target-arrow_24.svg';
import { ReactComponent as VocabularyIcon } from 'assets/v2/vocabulary_24.svg';
import { IconShell } from 'components/ui/atomic-components';
import { motion } from 'framer-motion';
import { useEffect, useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ShimmerText } from 'styles/animation';
import { type defaultTheme } from 'styles/theme';
import { FontM, FontS, FontXxl } from 'styles/typography';
import { AnimatedArrowIcon } from './animate-arrow-icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing[36]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
`;

const TitleIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing[20]};
`;

const Title = styled.div`
  ${FontXxl};

  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.textTitle1};
`;

const Subtitle = styled.div<{ animate: boolean }>`
  ${FontXxl};

  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textLabel};
  animation-delay: 50ms;

  ${({ animate }) => animate && ShimmerText};
`;

const Note = styled.div`
  ${FontS};

  color: ${({ theme }) => theme.colors.textLabel};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: ${({ theme }) => theme.spacing[40]};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const CardContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
  margin: ${({ theme: { spacing } }) => `0 ${spacing[16]}`};
`;

const Card = styled.div<{ color: keyof typeof defaultTheme.colors }>`
  padding: ${({ theme: { spacing } }) => `${spacing[28]} ${spacing[20]}`};
  border-radius: ${({ theme }) => theme.borderRadius.xxl};
  background: ${({ theme, color }) => theme.colors[color]};
  flex: 1;
`;

const CardIcon = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[12]};
`;

const CardTitle = styled.div<{ color: keyof typeof defaultTheme.colors }>`
  ${FontM};

  color: ${({ theme, color }) => theme.colors[color]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const CardContent = styled.div`
  ${FontS};

  color: ${({ theme }) => theme.colors.textBody2};
`;

const InfoContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyBlock = (): ReactElement => {
  const [shimmerAnimationEnable, setShimmerAnimationEnable] = useState(true);

  useEffect(() => {
    setTimeout(() => setShimmerAnimationEnable(false), 700);
  }, []);

  return (
    <Container>
      <TitleBlock>
        <TitleIcon>
          <AnimatedArrowIcon />
        </TitleIcon>
        <Title>
          <FormattedMessage id="drive_ai.input.empty.title" />
        </Title>
        <Subtitle animate={shimmerAnimationEnable}>
          <FormattedMessage id="drive_ai.input.empty.sub_title" />
        </Subtitle>
      </TitleBlock>

      <InfoContainer
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 32 }}
        transition={{
          duration: 0.6,
          delay: 0.05,
          damping: 20,
          stiffness: 80,
        }}
      >
        <Note>
          <FormattedMessage id="drive_ai.input.empty.note" />
        </Note>

        <CardContainer>
          <Card color="orange100">
            <CardIcon>
              <IconShell color="orange500" icon={TargetArrowIcon} />
            </CardIcon>

            <CardTitle color="orange600">
              <FormattedMessage id="drive_ai.input.empty.card1.title" />
            </CardTitle>

            <CardContent>
              <FormattedMessage id="drive_ai.input.empty.card1.sub_title" />
            </CardContent>
          </Card>

          <Card color="magenta100">
            <CardIcon>
              <IconShell color="magenta500" icon={VocabularyIcon} />
            </CardIcon>

            <CardTitle color="magenta600">
              <FormattedMessage id="drive_ai.input.empty.card2.title" />
            </CardTitle>
            <CardContent>
              <FormattedMessage id="drive_ai.input.empty.card2.sub_title" />
            </CardContent>
          </Card>
        </CardContainer>
      </InfoContainer>
    </Container>
  );
};
