import { type ID } from 'data';
import { type AccessAction } from 'data/roles/permissions';

export enum IntegrationProvider {
  Salesforce = 'SALESFORCE',
  Csv = 'CSV',
  GoogleSheets = 'GOOGLE_SHEETS',
  Hubspot = 'HUBSPOT',
  Freshsales = 'FRESHSALES',
  Zoho = 'ZOHO',
  Pipedrive = 'PIPEDRIVE',
  Netsuite = 'NETSUITE',
  Quickbooks = 'QUICKBOOKS',
  Xero = 'XERO',
  Workday = 'WORKDAY',
  Rippling = 'RIPPLING',
  BambooHr = 'BAMBOOHR',
  Gusto = 'GUSTO',
  Zenefits = 'ZENEFITS',
  Justworks = 'JUSTWORKS',
  Stripe = 'STRIPE',
  Chargebee = 'CHARGEBEE',
  Looker = 'LOOKER',
  GoogleAds = 'GOOGLE_ADS',
  LinkedinAds = 'LINKEDIN_ADS',
  Marketo = 'MARKETO',
  Totango = 'TOTANGO',
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  Zendesk = 'ZENDESK',
  Darwinbox = 'DARWINBOX',
  Outreach = 'OUTREACH',
  Lever = 'LEVER',
  Greenhouse = 'GREENHOUSE',
  Namely = 'NAMELY',
  Sapling = 'SAPLING',
  SageIntacct = 'SAGE_INTACCT',
  SAP = 'SAP',
  ZohoBooks = 'ZOHO_BOOKS',
  ZohoPeople = 'ZOHO_PEOPLE',
  MSDynamics365 = 'MS_DYNAMICS_365',
  ADP = 'ADP',
  HiBob = 'HIBOB',
  Paylocity = 'PAYLOCITY',
  Zuora = 'ZUORA',
  SaaSOptics = 'SAASOPTICS',
  AmazonS3 = 'AMAZON_S3',
  AWSRedShift = 'AWS_REDSHIFT',
  Snowflake = 'SNOWFLAKE',
  GoogleBigQuery = 'GOOGLE_BIGQUERY',
  Databricks = 'DATABRICKS',
  PowerBI = 'POWERBI',
  Tableau = 'TABLEAU',
  Gainsight = 'GAINSIGHT',
  ChurnZero = 'CHURNZERO',
  GoogleCloudStorage = 'GOOGLE_CLOUD_STORAGE',
  AmazonSeller = 'AMAZON_SELLER',
  Shopify = 'SHOPIFY',
  DrivetrainDatasets = 'DRIVETRAIN_DATASETS',
  DrivetrainLists = 'DRIVETRAIN_LISTS',
  AWSCostExplorer = 'AWS_COST_EXPLORER',
  GCPCostExplorer = 'GCP_COST_EXPLORER',
  Elasticsearch = 'ELASTICSEARCH',
  Deepgram = 'DEEPGRAM',
  MicrosoftAzureSqlDatabase = 'MICROSOFT_AZURE_SQL_DATABASE',
  GoogleAnalytics4 = 'GOOGLE_ANALYTICS_4',
  GoogleSearchConsole = 'GOOGLE_SEARCH_CONSOLE',
  Roller = 'ROLLER',
  Mailchimp = 'MAILCHIMP',
  BoostUp = 'BOOSTUP',
  ChartHop = 'CHARTHOP',
  SevenShifts = '7SHIFTS',
  Alexishr = 'ALEXISHR',
  Alliancehcm = 'ALLIANCEHCM',
  AlteraPayroll = 'ALTERA_PAYROLL',
  Breathe = 'BREATHE',
  CeridianDayforce = 'CERIDIAN_DAYFORCE',
  CezanneHr = 'CEZANNE_HR',
  Charlie = 'CHARLIE',
  Clayhr = 'CLAYHR',
  Cyberark = 'CYBERARK',
  EmploymentHero = 'EMPLOYMENT_HERO',
  Factorial = 'FACTORIAL',
  Freshteam = 'FRESHTEAM',
  GoogleWorkspace = 'GOOGLE_WORKSPACE',
  HrCloud = 'HR_CLOUD',
  HrPartner = 'HR_PARTNER',
  Humaans = 'HUMAANS',
  Humi = 'HUMI',
  InsperityPremier = 'INSPERITY_PREMIER',
  Intellihr = 'INTELLIHR',
  IrisCascade = 'IRIS_CASCADE',
  Jumpcloud = 'JUMPCLOUD',
  Kallidus = 'KALLIDUS',
  Keka = 'KEKA',
  Kenjo = 'KENJO',
  Lano = 'LANO',
  Lucca = 'LUCCA',
  MicrosoftEntraId = 'MICROSOFT_ENTRA_ID',
  Nmbrs = 'NMBRS',
  Officient = 'OFFICIENT',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  Oysterhr = 'OYSTERHR',
  Paycaptain = 'PAYCAPTAIN',
  Paychex = 'PAYCHEX',
  Paycor = 'PAYCOR',
  Payfit = 'PAYFIT',
  MongoDBAtlas = 'MONGODB_ATLAS',
  Recurly = 'RECURLY',
  SalesforceSandbox = 'SALESFORCE_SANDBOX',
  Clari = 'CLARI',
  Airtable = 'AIRTABLE',
  UKGPro = 'UKG_PRO',
  Tally = 'TALLY',
  Twilio = 'TWILIO',
  OneDrive = 'ONEDRIVE',
  Naviga = 'NAVIGA',
  Zipit = 'ZIPIT',
  Planhat = 'PLANHAT',
  Carta = 'CARTA',
  GoogleDrive = 'GOOGLE_DRIVE',
  Proliant = 'PROLIANT',
}

export interface IntegrationConnectionItem {
  id: ID;
  name: IntegrationProvider;
  displayName: string;
  description: string;
  loginUrl: string;
  category: string;
  clientId: string | null;
  isPremium: boolean;
}

export interface IntegrationDataSetListItem {
  id: ID;
  name: string;
  nameAtDestination: string;
  displayName: string;
  provider: IntegrationProvider;
  providerDisplayName: string;
  lastSyncedAt: string;
  syncStatus: TableSyncStatus;
  schemaName: string;
  instanceId: number;
  meta?: IntegrationDataSetListItemMeta;
  accessAction?: AccessAction;
  errors?: string[];
  allowSkipDbtExpectation?: boolean;
}

interface IntegrationDataSetListItemMeta {
  idAtSource?: string;
  name?: string;
  tabId?: string;
  tabName?: string;
  url?: string;
  lookerTableType?: LookerTableType;
  inProgress?: boolean;
}

export type DataSetTableSelection = {
  selected: IntegrationDataSetListItem[];
  unselected: IntegrationDataSetListItem[];
  entityType?: DataEntityType;
};

export enum LookerTableType {
  Query = 'QUERY',
  Sql = 'SQL',
  Look = 'LOOK',
}

export enum TableSyncStatus {
  Synced = 'SYNCED',
  Syncing = 'SYNCING',
  Failed = 'FAILED',
}

export enum DataEntityType {
  Object = 'OBJECT',
  Report = 'REPORT',
}

export enum IntegrationConnectedItemStatus {
  Connected = 'CONNECTED',
  Connecting = 'CONNECTING',
  Syncing = 'SYNCING',
  SyncFailed = 'SYNC_FAILED',
  Failed = 'FAILED',
}

export interface IntegrationConnectedItem {
  id: ID;
  name: IntegrationProvider;
  displayName: string;
  datasetDisplayName: string;
  status: IntegrationConnectedItemStatus;
  lastSyncedAt: string;
  createdBy: string;
  usernameUsed: string;
  category: string;
  categoryDisplayName: string;
  tables?: IntegrationDataSetListItem[];
  debugInfo?: DebugInfo;
  manualSyncEnabled: boolean;
}

export interface DebugInfo {
  bigQueryUrl: string;
  dbtGitUrl: string;
  provider?: Provider;
}

export enum Provider {
  NATIVE = 'Native',
}
export interface IntegrationModalContent extends IntegrationConnectionItem {
  heading?: string;
  subTitle?: string | null;
  content?: string | null;
  contactUsUrl?: string | null;
  supportUrl?: string | null;
}

export interface GProfileDetails {
  name: string;
  email: string;
  picture: string;
}

export type ItemInfo = { id: string | number; name: string };
export interface GSheetRangeInfo {
  id: string;
  name: string;
  sheets: ItemInfo[];
  namedRanges: ItemInfo[];
}

interface BaseConnectArgs {
  code?: string;
  redirectUrl?: string;
  service: number;
}

export interface MergeConnectArgs extends BaseConnectArgs {
  mergeIntegrationId?: string;
  publicToken?: string;
}

export interface LookerConnectArgs extends BaseConnectArgs {
  lookerClientId?: string;
  lookerClientSecret?: string;
  subdomain?: string;
}

export interface NetsuiteConnectArgs extends BaseConnectArgs {
  consumerKey?: string;
  consumerSecret?: string;
  tokenId?: string;
  tokenSecret?: string;
  accountId?: string;
}

export interface HubspotConnectArgs extends BaseConnectArgs {
  tokenKey?: string;
}

export interface QuickbooksArgs extends BaseConnectArgs {
  realmId?: string | null;
}

interface ZohoBooksArgs extends BaseConnectArgs {
  region?: string | null;
}

interface AirtableArgs extends BaseConnectArgs {
  codeVerifier?: string | null;
}

export type ConnectArgs =
  | HubspotConnectArgs
  | NetsuiteConnectArgs
  | LookerConnectArgs
  | MergeConnectArgs
  | QuickbooksArgs
  | ZohoBooksArgs
  | AirtableArgs;
