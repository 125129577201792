import { create } from 'zustand';

interface VersionStore {
  isLoading: boolean;
  isLoadingPreview: boolean;
  actions: {
    setIsLoading: (isLoading: boolean) => void;
    setIsLoadingPreview: (isLoading: boolean) => void;
  };
}

const useVersionStore = create<VersionStore>()((set) => ({
  isLoading: false,
  isLoadingPreview: false,
  actions: {
    setIsLoading: (isLoading) => {
      set({ isLoading });
    },
    setIsLoadingPreview: (isLoading) => {
      set({ isLoadingPreview: isLoading });
    },
  },
}));

// States
export const useIsLoading = (): VersionStore['isLoading'] =>
  useVersionStore((state) => state.isLoading);

export const useIsLoadingPreview = (): VersionStore['isLoadingPreview'] =>
  useVersionStore((state) => state.isLoadingPreview);

// Actions
export const useVersionActions = (): VersionStore['actions'] =>
  useVersionStore((state) => state.actions);
