import { motion } from 'framer-motion';
import { type ReactElement } from 'react';

export const AnimatedArrowIcon = (): ReactElement => {
  return (
    <svg fill="none" height="32" viewBox="0 0 16 16" width="32" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_974_50838)" id="group">
        <motion.path
          animate={{ rotate: 360, scale: 1 }}
          clipRule="evenodd"
          d="M16.0086 3C16.0086 3.28566 15.777 3.51724 15.4913 3.51724C14.97 3.51724 14.4701 3.72432 14.1015 4.09293C13.7329 4.46153 13.5258 4.96147 13.5258 5.48276C13.5258 5.76842 13.2942 6 13.0086 6C12.7229 6 12.4913 5.76842 12.4913 5.48276C12.4913 4.96147 12.2843 4.46153 11.9156 4.09293C11.547 3.72432 11.0471 3.51724 10.5258 3.51724C10.2402 3.51724 10.0086 3.28566 10.0086 3C10.0086 2.71434 10.2402 2.48276 10.5258 2.48276C11.0471 2.48276 11.547 2.27568 11.9156 1.90707C12.2843 1.53847 12.4913 1.03853 12.4913 0.517241C12.4913 0.231577 12.7229 0 13.0086 0C13.2942 0 13.5258 0.231577 13.5258 0.517241C13.5258 1.03853 13.7329 1.53847 14.1015 1.90707C14.4701 2.27568 14.97 2.48276 15.4913 2.48276C15.777 2.48276 16.0086 2.71434 16.0086 3Z"
          fill="url(#paint0_linear_974_50838)"
          fillRule="evenodd"
          id="Vector"
          initial={{ rotate: 0, scale: 0.8 }}
          transition={{ delay: 0.5, duration: 2, ease: 'easeInOut' }}
        />
        <path
          d="M12.9238 14.5978L8.3287 3.61094C8.1716 3.24037 7.81813 3 7.42538 3C7.03263 3 6.67916 3.25039 6.52206 3.62096L2.0742 14.6079C1.89747 15.0485 2.04475 15.5493 2.42767 15.8197C2.59459 15.9399 2.79096 15.99 2.97752 15.99C3.22299 15.99 3.46845 15.8898 3.66483 15.7096L7.4352 11.9738L11.3528 15.7296C11.5394 15.9099 11.7849 16 12.0205 16C12.2169 16 12.4133 15.9399 12.5802 15.8197C12.9631 15.5493 13.1006 15.0385 12.9238 14.6079V14.5978Z"
          fill="url(#paint1_linear_974_50838)"
          id="Vector_2"
        ></path>
      </g>

      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_974_50838"
          x1="10.0086"
          x2="16.0086"
          y1="3"
          y2="3"
        >
          <stop stopColor="#45C1FF"></stop>
          <stop offset="0.80898" stopColor="#B767F8"></stop>
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_974_50838"
          x1="7.5"
          x2="7.5"
          y1="16"
          y2="3"
        >
          <stop stopColor="#45C1FF"></stop>
          <stop offset="0.80898" stopColor="#B767F8"></stop>
        </linearGradient>
        <clipPath id="clip0_974_50838">
          <rect fill="white" height="16" width="16"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
