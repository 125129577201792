import { Divider as AntdDivider } from 'components/ui/atomic-components/divider';
import { Layout as LayoutAtom } from 'components/ui/atomic-components/layout-v2';
import styled from 'styled-components';

export default {
  PrimaryMenu: styled.ul`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${({ theme: { spacing } }) => spacing[0]};
    padding: ${({ theme: { spacing } }) => spacing[0]};
    gap: ${({ theme: { spacing } }) => spacing[12]};

    > li {
      list-style-type: none;
    }
  `,

  NavLogoWrapper: styled.div`
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Logo: styled.img`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Divider: styled(AntdDivider)`
    display: flex;
    width: ${({ theme: { spacing } }) => spacing[40]};
    padding: 4px 2px 3px;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme: { spacing } }) => spacing[8]};
    margin: ${({ theme: { spacing } }) => spacing[0]};
    min-width: ${({ theme: { spacing } }) => spacing[0]};
  `,

  Slider: styled(LayoutAtom.Sider)`
    &.ant-layout-sider {
      background: ${({ theme: { colors } }) => colors.gray00};
      z-index: ${({ theme }) => theme.zIndex[50]};
      border-right: 1px solid ${({ theme: { colors } }) => colors.gray200};
    }

    .nav-expand-button {
      display: none;
    }

    :hover {
      .drivetrain-logo {
        display: none;
      }

      .nav-expand-button {
        display: flex;
      }
    }

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      border-left: none;
      height: 100%;
      padding: ${({ theme: { spacing } }) => spacing[16]} ${({ theme: { spacing } }) => spacing[8]};
    }
  `,
};
