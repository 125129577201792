import { ReactComponent as WarningIcon } from 'assets/ic_alert-warning.svg';
import { IconText, Modal } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontM } from 'styles/typography';
import { useVersionMode } from './hooks/use-version-mode';

const DISCARD_BTN_ATTRIBUTE_NAME = 'data-discard-change';

const MessageWrapper = styled.div`
  ${FontM}

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  color: ${({ theme }) => theme.colors.textBody1};
`;

interface Props {
  versionName?: string;
  confirmDiscard: boolean;
  confirmSave: boolean;
  close: () => void;
}

export const PreSavePromptModal = ({
  versionName,
  confirmDiscard,
  confirmSave,
  close,
}: Props): ReactElement => {
  const { exitCurrentMode } = useVersionMode();

  return (
    <Modal
      cancelButtonProps={{ [DISCARD_BTN_ATTRIBUTE_NAME]: 'true' }}
      cancelText={
        <span {...{ [DISCARD_BTN_ATTRIBUTE_NAME]: 'true' }}>
          {confirmDiscard ? (
            <FormattedMessage id="model.versions.view.stay_on_page" />
          ) : (
            <FormattedMessage id="model.versions.view.discard_and_exit" />
          )}
        </span>
      }
      confirmLoading={false}
      destroyOnClose
      okButtonProps={{ danger: confirmDiscard }}
      okText={
        confirmDiscard ? (
          <FormattedMessage id="model.versions.view.yes_discard_and_exit" />
        ) : (
          <FormattedMessage id="model.versions.view.yes_save_and_exit" />
        )
      }
      open={confirmDiscard || confirmSave}
      title={
        <IconText
          icon={WarningIcon}
          text={
            confirmDiscard ? (
              <FormattedMessage id="model.versions.view.discard_prompt_title" />
            ) : (
              <FormattedMessage id="model.versions.view.save_prompt_title" />
            )
          }
        />
      }
      onCancel={(e: React.MouseEvent<HTMLButtonElement>) => {
        const targetElement = e.target as HTMLElement;
        // Check if the target element has a specific attribute
        const hasAttribute = targetElement.hasAttribute(DISCARD_BTN_ATTRIBUTE_NAME);

        if (hasAttribute && confirmSave) {
          exitCurrentMode();
        } else {
          close();
        }
      }}
      onOk={() => exitCurrentMode(confirmDiscard)}
    >
      <MessageWrapper>
        <div>
          {confirmDiscard ? (
            <FormattedMessage
              id="model.versions.view.discard_prompt_message_part1"
              values={{ versionName }}
            />
          ) : (
            <FormattedMessage
              id="model.versions.view.save_prompt_message_part1"
              values={{ versionName }}
            />
          )}
        </div>
        <div>
          <FormattedMessage id="model.versions.view.presave_prompt_message_part2" />
        </div>
      </MessageWrapper>
    </Modal>
  );
};
