import { Modal as AntModal } from 'antd';
import { ReactComponent as AlertTriangleIcon } from 'assets/v2/alert-triangle.svg';
import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { type FC, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { IconShell } from '../icon-shell';
import { ModalFooter } from './footer';
import { type ModalProps } from './index';

const Modal = styled(AntModal)`
  &&& {
    .ant-modal-header {
      border-bottom: none;
    }

    .ant-modal-footer {
      border-top: none;
    }

    .ant-modal-body {
      padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[16]} ${spacing[12]}`};
    }
  }
`;

export interface ConfirmationModalProps extends ModalProps {
  titleIcon?: ReactNode;
  type?: 'default' | 'delete';
}

export const ConfirmationModal: FC<React.PropsWithChildren<ConfirmationModalProps>> = ({
  children,
  cancelButtonProps,
  cancelText,
  okButtonProps,
  confirmLoading,
  okText,
  maskClosable = true,
  title,
  titleIcon,
  footerExtra,
  onCancel,
  onOk,
  type = 'default',
  ...props
}) => (
  <Modal
    closeIcon={<IconShell color="iconDefault" icon={CloseIcon} size="lg" />}
    destroyOnClose
    footer={
      <ModalFooter
        cancelButtonProps={cancelButtonProps}
        cancelText={cancelText || <FormattedMessage id="cancel" />}
        confirmLoading={confirmLoading}
        footerExtra={footerExtra}
        okButtonProps={{
          danger: type === 'delete',
          icon: type === 'delete' ? <IconShell color="gray00" icon={DeleteIcon} /> : undefined,
          ...okButtonProps,
        }}
        okText={okText || (type === 'delete' ? <FormattedMessage id="delete" /> : undefined)}
        onCancel={onCancel}
        onOk={onOk}
      />
    }
    maskClosable={maskClosable}
    title={
      <>
        {titleIcon ||
          (type === 'delete' ? <IconShell color="red600" icon={AlertTriangleIcon} /> : undefined)}
        {title}
      </>
    }
    onCancel={onCancel}
    onOk={onOk}
    {...props}
  >
    {children}
  </Modal>
);
