import { isNil } from 'lodash';
import { css } from 'styled-components';
import { defaultTheme } from 'styles/theme';

export type HideProps = { $hide?: boolean };

export const Hide = css`
  ${({ $hide }: HideProps) => {
    if ($hide) {
      return css`
        display: none;
      `;
    }
  }}
`;

type SpacingKeys = keyof typeof defaultTheme.spacing;

export interface StyledBorderProps {
  $b?: boolean;
  $bt?: boolean;
  $br?: boolean;
  $bb?: boolean;
  $bl?: boolean;
}

export interface StyledMarginsProps {
  $m?: SpacingKeys;
  $mt?: SpacingKeys;
  $mr?: SpacingKeys;
  $mb?: SpacingKeys;
  $ml?: SpacingKeys;
  $mx?: SpacingKeys;
  $my?: SpacingKeys;
}

export interface StyledPaddingProps {
  $p?: SpacingKeys;
  $pt?: SpacingKeys;
  $pr?: SpacingKeys;
  $pb?: SpacingKeys;
  $pl?: SpacingKeys;
  $px?: SpacingKeys;
  $py?: SpacingKeys;
}

export const StyledMargins = css`
  ${({ $m, $mt, $mr, $mb, $ml, $mx, $my }: StyledMarginsProps) => {
    let style = '';

    if (!isNil($m)) {
      style += `margin: ${`${$mt}px`};`;
    }
    if (!isNil($mt)) {
      style += `margin-top: ${`${$mt}px`};`;
    }
    if (!isNil($mr)) {
      style += `margin-right: ${`${$mr}px`};`;
    }
    if (!isNil($mb)) {
      style += `margin-bottom: ${`${$mb}px`};`;
    }
    if (!isNil($ml)) {
      style += `margin-left: ${`${$ml}px`};`;
    }
    if (!isNil($mx)) {
      style += `margin-left: ${`${$mx}px`}; margin-right: ${`${$mx}px`};`;
    }
    if (!isNil($my)) {
      style += `margin-top: ${`${$my}px`}; margin-bottom: ${`${$my}px`};`;
    }

    return css`
      ${style}
    `;
  }}
`;

export const StyledPaddings = css`
  ${({ $p, $pt, $pr, $pb, $pl, $px, $py }: StyledPaddingProps) => {
    let style = '';

    if (!isNil($p)) {
      style += `padding: ${`${$pt}px`};`;
    }
    if (!isNil($px)) {
      style += `padding-left: ${`${$px}px`}; padding-right: ${`${$px}px`};`;
    }
    if (!isNil($py)) {
      style += `padding-top: ${`${$py}px`}; padding-bottom: ${`${$py}px`};`;
    }
    if (!isNil($pt)) {
      style += `padding-top: ${`${$pt}px`};`;
    }
    if (!isNil($pr)) {
      style += `padding-right: ${`${$pr}px`};`;
    }
    if (!isNil($pb)) {
      style += `padding-bottom: ${`${$pb}px`};`;
    }
    if (!isNil($pl)) {
      style += `padding-left: ${`${$pl}px`};`;
    }

    return css`
      ${style}
    `;
  }}
`;

export const StyledBorders = css`
  ${({ $b, $bt, $br, $bb, $bl }: StyledBorderProps) => {
    let style = '';

    if ($b) {
      style += `border: 1px solid ${defaultTheme.colors.outlineDefault}`;
    }
    if ($bt) {
      style += `border-top: 1px solid ${defaultTheme.colors.outlineDefault}`;
    }
    if ($br) {
      style += `border-right: 1px solid ${defaultTheme.colors.outlineDefault}`;
    }
    if ($bb) {
      style += `border-bottom: 1px solid ${defaultTheme.colors.outlineDefault}`;
    }
    if ($bl) {
      style += `border-left: 1px solid ${defaultTheme.colors.outlineDefault}`;
    }

    return css`
      ${style}
    `;
  }}
`;
