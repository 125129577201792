import { IntegrationProvider } from 'data/integrations/fivetran';
import { getCallbackUrl } from '../../../common/util';
import {
  SalesforceContent,
  PremiumContent,
  GSheetsContent,
  HubspotContent,
  NetsuiteContent,
  QuickbooksContent,
  XeroContent,
  LookerContent,
  SalesforceSandboxContent,
  PipeDriveInfoContent,
  AirtableInfoContent,
  SnowFlakeUserNamePassInfoContent,
  SnowFlakeKeyPairInfoContent,
  SageInfoContent,
  BambooHrInfoContent,
} from './integrations';
import { RipplingInfoContent } from './integrations/rippling/info';
import { TwilioInfoContent } from './integrations/twillio/info';
import { type GetAuthUrlProps, type IntegrationAuthFlow } from './types';

export const IntegrationAuthFlowMap: {
  [key in IntegrationProvider]: IntegrationAuthFlow[];
} = {
  [IntegrationProvider.Pipedrive]: [
    {
      type: 'oauth',
      getAuthUrl: ({ item, stateString }: GetAuthUrlProps): string => {
        const { clientId, loginUrl } = item;

        // set DEPLOY_ENV as staging for callback url to work
        return `${loginUrl}?client_id=${clientId}&redirect_uri=${getCallbackUrl()}&state=${stateString}`;
      },
      infoContent: PipeDriveInfoContent,
    },
  ],
  [IntegrationProvider.Airtable]: [
    {
      type: 'oauth',
      getAuthUrl: ({ item, stateString }: GetAuthUrlProps): string => {
        const { clientId, loginUrl } = item;

        const codeVerifier = '2PPGhBvOOJNtr481myiqf4NlUuZRjdCBEHyoeVcBcgOkfVoWCtWEa';
        const codeChallenge = 'd97x6EfPMqOzB5rKsMeEVwxZB8_K8bN3rEMCC6Alu6I';

        return `${loginUrl}?client_id=${clientId}&redirect_uri=${getCallbackUrl()}&state=${stateString}~~codeVerifier~${codeVerifier}&scope=schema.bases%3Aread+data.records%3Aread+webhook%3Amanage&response_type=code&code_challenge_method=S256&code_challenge=${codeChallenge}`;
      },
      infoContent: AirtableInfoContent,
    },
  ],
  [IntegrationProvider.Salesforce]: [{ type: 'component', component: SalesforceContent }],
  [IntegrationProvider.SalesforceSandbox]: [
    {
      type: 'component',
      component: SalesforceSandboxContent,
    },
  ],
  [IntegrationProvider.GoogleSheets]: [{ type: 'component', component: GSheetsContent }],
  [IntegrationProvider.Netsuite]: [{ type: 'component', component: NetsuiteContent }],
  [IntegrationProvider.Quickbooks]: [{ type: 'component', component: QuickbooksContent }],
  [IntegrationProvider.Xero]: [{ type: 'component', component: XeroContent }],
  [IntegrationProvider.Looker]: [{ type: 'component', component: LookerContent }],
  [IntegrationProvider.ZohoBooks]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Hubspot]: [{ type: 'component', component: HubspotContent }],
  [IntegrationProvider.Snowflake]: [
    {
      type: 'inputs',
      name: 'User name and password',
      inputTitle: 'Set up a new Snowflake connection',
      infoContent: SnowFlakeUserNamePassInfoContent,
      inputs: [
        {
          name: 'accountId',
          type: 'input',
          label: 'Host',
          placeholder: 'your-account.snowflakecomputing.com',
          pattern: /.*?\.snowflakecomputing.com/,
          outputFormatter: (input: string) =>
            input.match(/(https:\/\/)?(.*?)\.snowflakecomputing\.com/)?.[2],
        },
        {
          name: 'usernameUsed',
          type: 'input',
          label: 'Username',
        },
        {
          name: 'password',
          type: 'password',
          label: 'Password',
        },
        {
          name: 'warehouse',
          type: 'input',
          label: 'Warehouse',
        },
        {
          name: 'database',
          type: 'input',
          label: 'Database',
        },
      ],
    },
    {
      type: 'inputs',
      name: 'Key pair',
      inputTitle: 'Set up a new Snowflake connection',
      infoContent: SnowFlakeKeyPairInfoContent,
      inputs: [
        {
          name: 'accountId',
          type: 'input',
          label: 'Host',
          placeholder: 'your-account.snowflakecomputing.com',
          pattern: /.*?\.snowflakecomputing\.com/,
          outputFormatter: (input: string) =>
            input.match(/(https:\/\/)?(.*?)\.snowflakecomputing\.com/)?.[2],
        },
        {
          name: 'usernameUsed',
          type: 'input',
          label: 'Username',
        },
        {
          name: 'consumerSecret',
          type: 'password',
          label: 'Private Key',
        },
        {
          name: 'warehouse',
          type: 'input',
          label: 'Warehouse',
        },
        {
          name: 'database',
          type: 'input',
          label: 'Database',
        },
      ],
    },
  ],
  [IntegrationProvider.Twilio]: [
    {
      type: 'inputs',
      inputTitle: 'Set up a new Twilio connection',
      infoContent: TwilioInfoContent,
      inputs: [
        {
          name: 'accountId',
          type: 'input',
          label: 'Account Sid',
        },
        {
          name: 'accessToken',
          type: 'password',
          label: 'Auth Token',
        },
      ],
    },
  ],
  [IntegrationProvider.Rippling]: [
    {
      type: 'inputs',
      inputTitle: 'Set up a new Rippling connection',
      infoContent: RipplingInfoContent,
      inputs: [
        {
          name: 'tokenKey',
          type: 'password',
          label: 'API Key',
        },
      ],
    },
  ],
  [IntegrationProvider.SageIntacct]: [
    {
      type: 'inputs',
      inputTitle: 'Set up a new Sage connection',
      infoContent: SageInfoContent,
      inputs: [
        {
          name: 'realmId',
          type: 'input',
          label: 'Company Id',
        },
        {
          name: 'clientId',
          type: 'input',
          label: 'User Id',
        },
        {
          name: 'clientSecret',
          type: 'password',
          label: 'Login password',
        },
        {
          name: 'consumerKey',
          type: 'input',
          label: 'Sender Id',
        },
        {
          name: 'consumerSecret',
          type: 'password',
          label: 'Sender password',
        },
      ],
    },
  ],
  [IntegrationProvider.BambooHr]: [
    {
      type: 'inputs',
      inputTitle: 'Set up a new BambooHR connection',
      infoContent: BambooHrInfoContent,
      inputs: [
        {
          name: 'subdomain',
          type: 'input',
          label: 'Subdomain',
          placeholder: 'mycompany',
        },
        {
          name: 'apiKey',
          type: 'input',
          label: 'API Key',
        },
      ],
    },
  ],
  [IntegrationProvider.Csv]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Freshsales]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Zoho]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Workday]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Gusto]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Zenefits]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Justworks]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Stripe]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Chargebee]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.GoogleAds]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.LinkedinAds]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.GoogleAnalytics]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Namely]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Lever]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Darwinbox]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Greenhouse]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Outreach]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Zendesk]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Totango]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Marketo]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Sapling]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.SAP]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.ZohoPeople]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.MSDynamics365]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.ADP]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.HiBob]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Paylocity]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Zuora]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.SaaSOptics]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.AmazonS3]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.AWSRedShift]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.GoogleBigQuery]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Databricks]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.PowerBI]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Tableau]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Gainsight]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.ChurnZero]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.GoogleCloudStorage]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Shopify]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.AmazonSeller]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.DrivetrainDatasets]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.DrivetrainLists]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.AWSCostExplorer]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.GCPCostExplorer]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Elasticsearch]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Deepgram]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.MicrosoftAzureSqlDatabase]: [
    { type: 'component', component: PremiumContent },
  ],
  [IntegrationProvider.GoogleAnalytics4]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.GoogleSearchConsole]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Roller]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Mailchimp]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.BoostUp]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.ChartHop]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.SevenShifts]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Alexishr]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Alliancehcm]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.AlteraPayroll]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Breathe]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.CeridianDayforce]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.CezanneHr]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Charlie]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Clayhr]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Cyberark]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.EmploymentHero]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Factorial]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Freshteam]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.GoogleWorkspace]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.HrCloud]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.HrPartner]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Humaans]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Humi]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.InsperityPremier]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Intellihr]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.IrisCascade]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Jumpcloud]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Kallidus]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Keka]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Kenjo]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Lano]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Lucca]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.MicrosoftEntraId]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Nmbrs]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Officient]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Okta]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Onelogin]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Oysterhr]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Paycaptain]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Paychex]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Paycor]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Payfit]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.MongoDBAtlas]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Recurly]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Clari]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.UKGPro]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Tally]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.OneDrive]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Naviga]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Zipit]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Planhat]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.Carta]: [{ type: 'component', component: PremiumContent }],
  [IntegrationProvider.GoogleDrive]: [{ type: 'component', component: PremiumContent }],
};
