import { type QueryClient } from '@tanstack/react-query';
import { type ID } from 'data';
import { type ListRow, type RowStructure } from 'data/modelling/lists';
import produce from 'immer';
import { isEmpty, isNil, omit } from 'lodash';
import { type ListGridRow } from '../../types';

export const isAllRowValuesEmpty = (data: RowStructure): boolean => {
  if (!data) {
    return true;
  }

  return Object.keys(data).every((key) => isNil(data[key]));
};

export const getRowBackendIds = (rowIds: ID[], rows: ListGridRow[]): ID[] => {
  const rowIdsSet = new Set(rowIds);

  return rows
    .filter((row) => rowIdsSet.has(row.id) && !!row.backendId)
    .map((row) => row.backendId as number);
};

export const replaceSubstringsToUppercase = (
  originalString?: string,
  substrings?: string[],
): string | undefined => {
  if (!originalString || isEmpty(substrings)) {
    return originalString;
  }

  const pattern = new RegExp(
    (substrings?.map((substring) => String.raw`\b${substring}\s?(?=\()`) || []).join('|'),
    'gi',
  );

  return originalString.replace(pattern, (match) => match.toUpperCase());
};

export const getUpdatedRowData = (data: RowStructure, maskedColumns: string[]): RowStructure => {
  if (isEmpty(maskedColumns)) {
    return data;
  }

  return omit(data, maskedColumns);
};

export const updateRowValuesInCacheOnAddRow = ({
  listId,
  queryClient,
  rowToBeAdded,
}: {
  listId: number;
  queryClient: QueryClient;
  rowToBeAdded?: Partial<ListRow>;
}): void => {
  queryClient.setQueryData(
    ['list-row-values', String(listId)],
    (oldData: Record<string, string[]> | undefined): Record<string, string[]> => {
      return produce(oldData, (draft) => {
        const existingListRowValues = oldData ?? {};

        Object.keys(existingListRowValues ?? {}).forEach((colName) => {
          const addedValue = (rowToBeAdded?.data?.[colName] ?? '') as string;

          if (!existingListRowValues[colName]?.includes(addedValue)) {
            draft?.[colName].push(addedValue);
          }
        });
      }) as Record<string, string[]>;
    },
  );
};
