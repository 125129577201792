import { type FC } from 'react';
import styled from 'styled-components';
import { StyledMargins, type StyledMarginsProps } from 'styles/styled-atoms';
import { type SizeType } from '../spinner-icon';

const Wrapper = styled.div`
  ${StyledMargins};

  position: relative;
  left: 20px;
  width: 6px;
  height: 6px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.colors.primary500};
  color: ${({ theme }) => theme.colors.primary500};
  animation: dot-flashing 0.6s infinite linear alternate;
  animation-delay: 0.3s;

  ::before,
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 6px;
    height: 6px;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    background-color: ${({ theme }) => theme.colors.primary500};
    color: ${({ theme }) => theme.colors.primary500};
    animation: dot-flashing 0.6s infinite alternate;
  }

  ::before {
    left: -10px;
    animation-delay: 0s;
  }

  ::after {
    left: 10px;
    animation-delay: 0.6s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${({ theme }) => theme.colors.primary500};
    }

    50%,
    100% {
      background-color: ${({ theme }) => theme.colors.gray00};
    }
  }
`;

export const DotFlashingLoader: FC<
  React.PropsWithChildren<Partial<SizeType> & { className?: string } & StyledMarginsProps>
> = ({ ...rest }) => {
  return <Wrapper {...rest} />;
};
