import { defaultKeymap, indentWithTab } from '@codemirror/commands';
import { json } from '@codemirror/lang-json';
import { Compartment, EditorState } from '@codemirror/state';
import { EditorView, keymap, placeholder } from '@codemirror/view';
import { useRef, useEffect, type ReactElement } from 'react';
import styled from 'styled-components';
import { FontS } from 'styles/typography';

const Editor = styled.div`
  height: 450px;

  .cm-editor {
    outline: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    box-shadow: ${({ theme }) => theme.shadow.softMicro};
    height: 100%;
    background-color: ${({ theme }) => theme.colors.bgLight};
    padding: ${({ theme }) => theme.spacing['8']};
  }

  .cm-editor.cm-focused {
    outline: 1px solid ${({ theme }) => theme.colors.primary400};
    border-radius: ${({ theme }) => theme.borderRadius.xs};
  }

  .cm-scroller {
    border-radius: ${({ theme }) => theme.borderRadius.xs};
  }

  .cm-content {
    padding: 0;
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    width: 100%;
    height: 100%;
  }

  .cm-line {
    ${FontS};

    width: 100%;
    white-space: break-spaces;
    overflow-wrap: break-word;
    color: ${({ theme }) => theme.colors.textBody2};
    padding: ${({ theme }) => theme.spacing[2]};

    .cm-placeholder {
      margin-top: ${({ theme }) => theme.spacing[2]};
    }
  }
`;

const language = new Compartment();

interface Props {
  value?: string;
  placeholderText?: string;
  setEditorView?: (editorView: EditorView | null) => void;
}

export const CodeEditor = ({ value, placeholderText, setEditorView }: Props): ReactElement => {
  const editorDomRef = useRef(null);
  const editorViewRef = useRef<EditorView>();

  useEffect(() => {
    if (editorDomRef.current === null) {
      return;
    }

    const startState = EditorState.create({
      doc: value,
      extensions: [
        placeholder(placeholderText || ''),
        keymap.of(defaultKeymap),
        keymap.of([indentWithTab]),
        language.of(json()),
      ],
    });

    const view = new EditorView({
      state: startState,
      parent: editorDomRef.current,
    });

    view.focus();
    view.dispatch({ selection: { anchor: value?.length ?? 0, head: value?.length ?? 0 } });

    setEditorView?.(view);
    editorViewRef.current = view;

    return () => {
      view.destroy();
      setEditorView?.(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, placeholderText]);

  return <Editor ref={editorDomRef} />;
};
