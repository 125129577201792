import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { Button } from 'components/ui/atomic-components/button';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { VersionAccessModes } from '../../model/version-view-banner/constants';
import { isInVersionMode } from '../../model/version-view-banner/utils';
import { CreateNewListModal } from '../crud-components/create-list-modal';

export const NewListButton = (): ReactElement => {
  const [openCreateNewListModal, setOpenCreateNewListModal] = useState(false);
  const listCreationRestricted = isInVersionMode([
    VersionAccessModes.View,
    VersionAccessModes.SnapshotEdit,
  ]);

  if (listCreationRestricted) {
    return <></>;
  }

  return (
    <>
      <Button
        icon={<IconShell icon={PlusIcon} />}
        size="middle"
        type="primary"
        onClick={() => setOpenCreateNewListModal(true)}
      >
        <FormattedMessage id="lists.create" />
      </Button>
      <CreateNewListModal
        closeModal={() => setOpenCreateNewListModal(false)}
        open={openCreateNewListModal}
      />
    </>
  );
};
