export const getInitialFieldValues = (): Record<string, undefined> => {
  return {
    metrics: undefined,
    type: undefined,
    rowLabels: undefined,
    columnLabels: undefined,
    versionLabels: undefined,
    stackingType: undefined,
    pieChartType: undefined,
    dataLabels: undefined,
    labelType: undefined,
    legendPosition: undefined,
    overlayPlanOnActual: undefined,
    timeSummary: undefined,
    seriesColorMap: undefined,
    baselineVersion: undefined,
    compareStatistic: undefined,
    showCompareStatisticAs: undefined,
    baselineScenario: undefined,
    scenarioCompareStatistic: undefined,
    showScenarioCompareStatisticAs: undefined,
    comboConfig: undefined,
    waterfallInvertMetricsMap: undefined,
    waterfallShowConnectingLines: undefined,
    waterfallShowZeroValues: undefined,
    waterfallStartingBlock: undefined,
    waterfallSubTotals: undefined,
    waterfallEndingBlock: undefined,
    sparklineColor: undefined,
    showSparkline: undefined,
    showVerticalGridLines: undefined,
    tableChartViewType: undefined,
    tableChartCellSize: undefined,
    tableChartCellEditable: undefined,
    tableRowSummaryPosition: undefined,
    tableRowsExpanded: undefined,
    tableRepeatRowLabels: undefined,
    tableColumnsExpanded: undefined,
    tableAlternateRowColor: undefined,
    tableColoredColumnHeader: undefined,
    tableShowCellGrids: undefined,
    tableFormattingRules: undefined,
    tableBlankRowsPlacement: undefined,
    axisConfig: undefined,
  };
};
