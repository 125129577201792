import { type ID } from 'data';
import { DimensionAllMarker } from 'data/dimension';
import { type FrameElement } from 'data/reports/types';
import { VERSION } from 'data/versions';
import { isUndefined } from 'lodash';
import { defaultTheme } from 'styles/theme';
import { getLeafDimensionsInfo, getModifiedSeriesDimensions } from '../../utils';
import { generateSeriesColorMapKey } from './generate-series-color-map-key';
import { getBaseColorVersion } from './get-base-color-version';
import { getSeriesColor } from './get-series-color';
import { getVersionsColorShadeMap } from './get-version-color-shade-map';

interface Props {
  rows: FrameElement[];
  chartVersions: string[];
  chartId: ID;
  isScenarioPresent: boolean;
  hideAggregatesFor: string[];
}

export const generateSeriesColorMap = ({
  rows,
  chartVersions,
  chartId,
  isScenarioPresent,
  hideAggregatesFor,
}: Props): Record<string, string> => {
  const colorMap: Record<string, string> = {};

  const seriesLeafDimensionsInfo = getLeafDimensionsInfo(rows);

  let currIdx = 0;
  const seriesIdxMap: Record<string, number> = {};

  const getSeriesIdx = ({
    idx,
    version,
    colorMapKey,
  }: {
    idx: number;
    version: string | null;
    colorMapKey: string;
  }) => {
    if (version === null) {
      return idx;
    }

    const encodedDimensions = colorMapKey
      .split('~')
      .filter((k) => k !== version)
      .join('~');

    if (isUndefined(seriesIdxMap[encodedDimensions])) {
      seriesIdxMap[encodedDimensions] = currIdx;
      currIdx += 1;
    }

    return seriesIdxMap[encodedDimensions];
  };

  const versionsColorShadeMap = getVersionsColorShadeMap(chartVersions, isScenarioPresent);
  const baseColorVersion = getBaseColorVersion(chartVersions, isScenarioPresent);
  const hash = chartId;

  seriesLeafDimensionsInfo.forEach((dimensions, idx) => {
    const hideDataPoint = (hideAggregatesFor || []).some(
      (agg) => dimensions[agg]?.value === DimensionAllMarker,
    );

    if (hideDataPoint) {
      return;
    }

    const version = dimensions[VERSION]?.value || null;

    const colorSeriesDimensions = getModifiedSeriesDimensions(dimensions);
    const colorMapKey = generateSeriesColorMapKey(colorSeriesDimensions);

    const seriesIdx = getSeriesIdx({ idx, version, colorMapKey });
    const seriesCount = version
      ? seriesLeafDimensionsInfo.length / chartVersions.length
      : seriesLeafDimensionsInfo.length;

    const seriesColor = getSeriesColor({
      seriesIdx,
      seriesCount,
      version,
      hash,
      versionsColorShadeMap,
    });

    if (version === null || version === baseColorVersion) {
      colorMap[colorMapKey] = seriesColor;
    } else {
      const baseColorMapKey = generateSeriesColorMapKey({
        ...colorSeriesDimensions,
        [VERSION]: baseColorVersion,
      });

      if (!colorMap[baseColorMapKey]) {
        const colorList = defaultTheme.chartColors.base7;

        colorMap[baseColorMapKey] = colorList[Math.floor(Math.random() * colorList.length)];
      }
    }
  });

  return colorMap;
};
