import { ReactComponent as AlertTriangleFilledIcon } from 'assets/v2/alert-triangle-filled.svg';
import { IconShell, Tooltip } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { FontS, SingleLineEllipsis } from 'styles/typography';

const Wrapper = styled.div`
  ${FontS};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing[4]};
  margin-top: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.orange700};

  svg {
    flex-shrink: 0;
  }

  span {
    ${SingleLineEllipsis};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.gray200};
  top: 10px;
  position: relative;
`;

export const SensitiveInfoWarning = (): ReactElement => {
  const hasSensitiveInfo = useListStoreContext((s) => s.config.hasSensitiveInfo);

  if (!hasSensitiveInfo) {
    return <></>;
  }

  return (
    <>
      <Tooltip title={<FormattedMessage id="lists.sensitive_info.tooltip" />}>
        <Wrapper>
          <IconShell color="orange700" icon={AlertTriangleFilledIcon} />
          <span>
            <FormattedMessage id="lists.sensitive_info.title" />
          </span>
        </Wrapper>
      </Tooltip>

      <Divider />
    </>
  );
};
