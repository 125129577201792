import styled from 'styled-components';
import {
  StyledPaddings,
  StyledBorders,
  type StyledPaddingProps,
  type StyledBorderProps,
} from 'styles/styled-atoms';

export const PopoverFooter = styled.div.attrs<
  StyledPaddingProps & StyledBorderProps & { bordered?: boolean }
>(({ bordered }) => ({
  $bt: bordered || false,
}))<StyledPaddingProps & StyledBorderProps & { bordered?: boolean }>`
  ${StyledPaddings};
  ${StyledBorders};

  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing[32]};

  > div:last-child {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;
